import DashboardLayout from '../../layouts/DashboardLayout'
import Breadcrumb from '../../components/Breadcrumb'
// import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { useAppSelector } from '../../app/hooks'
import { selectAuth } from '../auth/authSlice'

function DataStudioLinks() {
  const { user } = useAppSelector(selectAuth)

  const breadcrumb = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Reports' },
  ]

  // const fbConnId = user.email === 'guest@digitalwill.co.jp'
  //                  ? 'AKfycbzisQAeiuPHgcfvS0c02hR9llLofZ3jqj1j3NZBnzA'
  //                  : 'AKfycbyAht_whT-Xo3hnTJHPRhjPiyO4nRmaLjjVKuENv6g'

  const connectors = [{
    // id: fbConnId,
    id: 'AKfycbzvWsG5IMOHiRT8QNftAu5qNFEDQiEoumre3Qsp8YA',
    title: 'Facebook Ads',
    logo: 'facebook-logo.svg'
  }, {
    id: 'AKfycbzAWLX0vTASsTwNd15u11KKmyuA05lKVCJ0Wn9i-Zi0',
    title: 'Yahoo! Search Ads',
    logo: 'yahoo-logo.png'
  }, {
    id: 'AKfycbynMxFLcUWGUVFGbuwoTMTngSnPv9GP4SWUHvGEZ4HB',
    title: 'LINE',
    logo: 'LINE-logo.png',
  }, {
    id: 'AKfycbxTXxQglT7cUYHKvbx2nLnXJQGnK7xwaR_RQFdwcKVy',
    title: 'Yahoo! Display Ads',
    logo: 'yahoo-logo.png'
  }, {
    id: 'AKfycbxt8N53ti79iq1koAC6Ox54LcXco156nunTPE7YBb8',
    title: 'Google Ads',
    logo: 'google-logo.png'
  }, {
    id: 'AKfycbw8jyiGueDsRmKrFi6jhASs95Mbph0JtCZ6QQzTrsSL',
    title: 'Teads',
    logo: 'logo-teads-80x30.svg'
  }]

  return (
    <DashboardLayout>
      <Breadcrumb pages={breadcrumb} />
      <div className="mt-6 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Create data studio report
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            Select a connector to integrate with Google Data Studio
          </p>
        </div>
      </div>

      <ul className="mt-7 grid grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-4 lg:grid-cols-6">
        {connectors.map(connector => (
          <li key={connector.id} className="border border-gray-300 rounded-lg hover:border-purple-600 text-center cursor-pointer py-3 px-5">
            <a href={`https://datastudio.google.com/datasources/create?connectorId=${connector.id}`} target="_blank" rel="noreferrer">
              <img className="h-16 w-16 mx-auto" src={`${process.env.PUBLIC_URL}/img/${connector.logo}`} alt="logo" />
              <p className="mt-2 text-sm text-gray-900">
                {connector.title}
              </p>
            </a>
          </li>
        ))}
      </ul>

    </DashboardLayout>
  )
}

// function ProxyConnector() {
//   const connectors = [
//     {
//       // id: 'AKfycbzisQAeiuPHgcfvS0c02hR9llLofZ3jqj1j3NZBnzA',
//       id: 'AKfycbzvWsG5IMOHiRT8QNftAu5qNFEDQiEoumre3Qsp8YA',
//       title: 'Facebook Ads',
//       logo: 'facebook-logo.svg'
//     },
//     {
//       id: 'AKfycbxRArKAPSGRWCz162cmgYGNgkn0j_NPCGvR3w58i6UY',
//       title: 'Yahoo! JAPAN Ads',
//       logo: 'yahoo-logo.png',
//     },
//     {
//       id: 'AKfycby0vUQ8sMgCVDN0i-d-TEjSNKyXhCgjcJZkYWDezWo',
//       title: 'LINE',
//       logo: 'LINE-logo.png',
//     },
//     {
//       id: 'AKfycbwHRTSE88lmj5sPqEsobF0dVfoYxsTlw_iVSZttQQzm',
//       title: 'Google',
//       logo: 'google-logo.png'
//     },
//     {
//       id: 'AKfycbxrVsGzP_lxLPWfwGwzudrhCnVd3wVwzqlhad9NHAQ',
//       title: 'TikTok',
//       logo: 'tiktok-logo.png'
//     }
//   ]

//   return (
//     <div className="mt-16 rounded-md bg-blue-50 p-4">
//       <div className="flex">
//         <div className="flex-shrink-0">
//           <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
//         </div>
//         <div className="ml-3">
//           <h3 className="text-sm font-medium text-blue-700">
//             Proxy Connectors
//           </h3>
//           <p className="mt-2 text-sm text-gray-700">
//             The following DataStudio connectors will display the data directly from the ad provider APIs,
//             use this for testing only.
//           </p>
//           <div className="mt-2 text-sm text-blue-700">
//             <ul className="mt-4 grid grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-4 lg:grid-cols-6">
//               {connectors.map(connector => (
//                 <li key={connector.id} className="border border-gray-300 rounded-lg hover:border-purple-600 text-center cursor-pointer py-2 px-2">
//                   <a href={`https://lookerstudio.google.com/datasources/create?connectorId=${connector.id}`} target="_blank">
//                     <img className="h-12 w-12 mx-auto" src={`${process.env.PUBLIC_URL}/img/${connector.logo}`} alt="" />
//                     <p className="mt-1 text-sm text-gray-600">
//                       {connector.title}
//                     </p>
//                   </a>
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

export default DataStudioLinks
