import { XCircleIcon } from '@heroicons/react/20/solid'

interface Props {
  className?: string
  children: React.ReactNode
}

function AlertError({ className, children }: Props) {
  return (
    <div className={className + " rounded-md bg-red-50 p-4"}>
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">
            {children}
          </h3>
        </div>
      </div>
    </div>
  )
}

export default AlertError
