import axios, { AxiosError } from 'axios'
import { useState, useEffect, useRef, ChangeEventHandler, ComponentProps } from 'react'
import { useParams } from 'react-router-dom'
import { parse } from 'csv-parse/browser/esm'
import * as yup from 'yup'
import { Formik, Form, Field, FieldProps } from 'formik'
import classNames from 'classnames'

import Breadcrumb from '../../components/Breadcrumb'
import Toggle from '../../components/Toggle'
import DashboardLayout from '../../layouts/DashboardLayout'
import UserService from '../../services/UserService'
import DataSourceService from '../../services/DataSourceService'
import Error403 from '../../components/Error403'


const fieldPairsSchema = yup.object({
  date: yup.string().required(),
  currencyCode: yup.string().optional(),
  campaignId: yup.string().optional(),
  campaignName: yup.string().optional(),
  adsetId: yup.string().optional(),
  adsetName: yup.string().optional(),
  adId: yup.string().optional(),
  adName: yup.string().optional(),
  accountId: yup.string().optional(),
  accountName: yup.string().optional(),
  platform: yup.string().optional(),
  dataLevel: yup.string().optional(),
})


interface FieldLabelType {
  name: ComponentProps<typeof Field>,
  label: string,
  isRequired?: boolean
}

interface AxiosResponseDataType {
  [field: string]: string[]
}

interface AxiosSuccessResponseType {
  message?: string
}

const fieldText: FieldLabelType[] = [
  {name: 'platform', label: 'Platform', isRequired: true},
  {name: 'dataLevel', label: 'Data Level/Resource'},
  {name: 'accountId', label: 'Account ID'},
  {name: 'accountName', label: 'Account Name'},
]

const fieldSelector: FieldLabelType[] = [
  {name: 'date', label: 'Date', isRequired: true},
  {name: 'currencyCode', label: 'Currency Code'},
  {name: 'campaignId', label: 'Campaign ID'},
  {name: 'campaignName', label: 'Campaign Name'},
  {name: 'adsetId', label: 'Ad Set/Group ID'},
  {name: 'adsetName', label: 'Ad Set/Group Name'},
  {name: 'adId', label: 'Ad ID'},
  {name: 'adName', label: 'Ad Name'},
]

const getButtonClass = (disabled?: boolean) => classNames(
  "inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150",
  {
    "hover:bg-indigo-700 bg-indigo-600": !disabled,
    "bg-indigo-500 hover:bg-indigo-400 cursor-not-allowed": disabled,
  }
)

const LoadingSpin = ({ hide }: { hide?: boolean }) => {
  if (hide) return null
  return (
    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}

const Required = ({isRequired}: {isRequired?: boolean}) => {
  if (!isRequired) return null
  return <span className="text-red-600 mx-1">*</span>
}

function UploadCsvPage() {
  const [isLoading, setIsLoading] = useState(false)
  // const [unauthorized, setUnauthorized] = useState(false)
  const csvFileRef = useRef<HTMLInputElement>(null)
  const [records, setRecords] = useState<string[][]>([])
  const [columnTitles, setColumnTitles] = useState<string[]>([])
  const [error, setError] = useState<string>('')

  useEffect(() => {
    document.title = 'Upload CSV - Syncotron-9000'
  }, [])

  // if (unauthorized) {
  //   return <Error403 />
  // }

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'CSV Upload' },
  ]

  const handleCsvFileChange: ChangeEventHandler<HTMLInputElement> = async (ev) => {
    // console.log(ev)
    if (ev.target.files && ev.target.files.length > 0) {
      setIsLoading(true)
      const file = ev.target.files[0]
      console.log(file.name, file.size)
      const content = await file.text()
      parse(content, {relax_column_count: true}, (err, records) => {
        if (err) {
          console.error(err)
          setError(err.message)
        } else {
          setError('')
          if (records && Array.isArray(records) && records.length > 0 && Array.isArray(records[0])) {
            setRecords(records)
            setColumnTitles(records[0])
          }
          console.log(`${records.length} rows loaded`)
        }
        setIsLoading(false)
      })
    }
  }

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages}/>
      { error && (
        <div className="w-full border-2 border-red-600 bg-yellow-100 mt-4 p-4">
          <h2 className="text-xl font-medium mb-2 text-red-900">Error</h2>
          <div>{error}</div>
        </div>
      )}
      <div className="md:flex mt-6 md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Step 1: Upload CSV
          </h2>
        </div>
      </div>

      <div className="text-sm mt-5">
        <button
          className={getButtonClass(isLoading)}
          onClick={() => csvFileRef.current?.click()}
        >
          <LoadingSpin hide={!isLoading}/>
          Select a CSV File...
        </button>
        <input id="csvFile" type="file" accept="application/csv,.csv" className="hidden" ref={csvFileRef} onChange={handleCsvFileChange}/>
        <TableContent records={records} isLoading={isLoading}/>
      </div>

      <div className="md:flex mt-6 md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Step 2: Select Matching Columns
          </h2>
        </div>
      </div>

      <CsvUploadForm columnTitles={columnTitles} records={records}/>
    </DashboardLayout>
  )
}

function CsvUploadForm({
  columnTitles, records
}: {
  columnTitles: string[]
  records: string[][]
}) {
  const [nonFieldErrors, setNonFieldErrors] = useState<string[]>([])
  const [message, setMessage] = useState<string>('')
  return (
    <Formik
      initialValues={{
        date: '',
        currencyCode: '',
        campaignId: '',
        campaignName: '',
        adsetId: '',
        adsetName: '',
        adId: '',
        adName: '',
        accountId: '',
        accountName: '',
        platform: '',
        dataLevel: '',
      }}
      validationSchema={fieldPairsSchema}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setMessage('')
        const data = {...values, records}
        const service = new DataSourceService()
        try {
          const response = await service.updloadCsv(data)
          if (response.status === 201) {
            setMessage('CSV has been uploaded')
          }
          // on success, clear the errors
          setNonFieldErrors([])
        } catch (err) {
          const axiosError = err as AxiosError
          if (axiosError.isAxiosError) {
            // console.error(err)
            if (axiosError.response) {
              // check if the response is an object, otherwise it could be a string
              if (axiosError.response!.data && typeof axiosError.response!.data === 'object') {
                console.error('error response data', axiosError.response!.data)
                const errorResponse = axiosError.response!.data as AxiosResponseDataType
                const errorsDict = Object.fromEntries(
                  Object.entries(errorResponse).map(
                    ([key, value]: [string, string[]]) => [key, value.join(', ')]
                  )
                )
                // console.log(errorsDict)
                if (errorResponse.nonFieldErrors) {
                  setNonFieldErrors(errorResponse.nonFieldErrors)
                }
                setErrors(errorsDict)
              } else if (axiosError.response!.status >= 500) {
                console.error('500 error', axiosError.response)
                setNonFieldErrors(['Internal Server Error'])
              } else if (axiosError.response!.status >= 400) {
                console.error('400 error', axiosError.response)
                setNonFieldErrors(['Unknown Error'])
              }
            } else {
              console.error('Error contains no response', axiosError.message)
            }
          }
        }
        setSubmitting(false)
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          { Array.isArray(nonFieldErrors) && nonFieldErrors.length > 0 && (
            <div className="flex flex-col my-4 px-4 text-red-600">
              <h2 className="font-semibold">Errors</h2>
              <ul className="list-disc">
                {nonFieldErrors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )}
          <div className="w-full mt-4 grid md:grid-cols-4 sm:grid-cols-2 gap-4">
            {fieldText.map(({name, label, isRequired}) => (
              <Field key={name} name={name} isRequired={isRequired}>
                {({ field, form, meta }: FieldProps<string>) => (
                  <div className="flex flex-col">
                    <label htmlFor={field.name} className={classNames("pl-3 pr-3 text-sm text-slate-500", {"font-semibold": isRequired})}>
                      {label}
                      <Required isRequired={isRequired}/>
                    </label>
                    <input {...field}
                      id={field.name}
                      className="border-none rounded-lg bg-sky-50 shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-600 px-3 py-2"
                      required={isRequired}
                    />
                    {meta.error && (
                      <div className="pl-3 pr-3 text-sm mt-2 text-red-500">
                        {meta.error}
                      </div>
                    )}
                  </div>
                )}
              </Field>
            ))}

            {fieldSelector.map(({name, label, isRequired}) => (
              <Field key={name} name={name} isRequired={isRequired}>
                {({ field, form, meta }: FieldProps<string>) => (
                  <div className="flex flex-col">
                    <label htmlFor={field.name} className={classNames("pl-3 pr-3 text-sm text-slate-500", {"font-semibold": isRequired})}>
                      {label}
                      <Required isRequired={isRequired}/>
                    </label>
                    <select {...field}
                      id={field.name}
                      className="border-none rounded-lg bg-sky-50 shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-600"
                      required={isRequired}
                    >
                      <option>Please select a field...</option>
                      {columnTitles.map((row) => (
                        <option key={row} value={row} selected={field.value === row}>{row}</option>
                      ))}
                    </select>
                    {meta.error && (
                      <div className="pl-3 pr-3 text-sm mt-2 text-red-500">
                        {meta.error}
                      </div>
                    )}
                  </div>
                )}
              </Field>
            ))}
          </div>

          <div className="mt-4">
            <button
              type="submit"
              className={getButtonClass(isSubmitting)}
              disabled={isSubmitting}
            >
              <LoadingSpin hide={!isSubmitting}/>
              Submit
            </button>

          </div>
          {message && (
            <div className="mt-2 text-green-700">
              {message}
            </div>
          )}
        </Form>
      )}
    </Formik>
  )
}

function TableContent({
  records,
  isLoading,
}: {
  records: string[][]
  isLoading: boolean
}) {
  if (isLoading)
    return (
      <div className="w-full py-4 align-middle">
        Loading...
      </div>
    )
  // const rows = records.map((row, index) => ([index, ...row]))
  if (!records || records.length < 1)
    return (
      <div className="w-full py-4 align-middle">
        Please select a CSV file
      </div>
    )
  const titleRow = records[0]
  const colCount = titleRow.length
  const rows = records.slice(1)
  return (
    <div className="w-full overflow-x-auto">
      <table className="mt-8 border-collapse border border-slate-400">
        <thead className="bg-slate-100 dark:bg-slate-700">
          <tr>
            {titleRow.map((cell, index) => (
              <th key={index} className="border border-slate-400 px-1">{cell}</th>
            ))}
          </tr>
        </thead>
        <TableBody {...{rows, colCount}}/>
      </table>
    </div>
  )
}

function TableBody({
  rows, colCount
}: {
  rows: string[][]
  colCount: number
}) {
  if (rows.length <= 15) {
    return (
      <tbody>
        {rows.map((row, ridx) => (
          <tr key={ridx}>
            {row.map((cell, cidx) => (
              <td key={`${ridx};${cidx}`} className="border border-slate-400 px-1">{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    )
  }
  // if records > 15 then only show 5 top ... 5 bottom
  return (
    <tbody>
      {rows.slice(0, 5).map((row, ridx) => (
        <tr key={ridx} className="border-2 border-slate-300">
          {row.map((cell, cidx) => (
            <td key={`${ridx};${cidx}`} className="border border-slate-400 px-1">{cell}</td>
          ))}
        </tr>
      ))}
      <tr><td colSpan={colCount} className="text-center">{'...'}</td></tr>
      {rows.slice(-10).map((row, ridx) => (
        <tr key={ridx}>
          {row.map((cell, cidx) => (
            <td key={`${ridx};${cidx}`} className="border border-slate-400 px-1">{cell}</td>
          ))}
        </tr>
      ))}
    </tbody>
  )
}

export default UploadCsvPage
