import HelpLayout from '../layouts/HelpLayout'

function HelpHome() {
  return (
    <HelpLayout>
    </HelpLayout>
  )
}

export default HelpHome 
