import BaseService from './BaseService'

export interface ITeadsAccount {
  apiKey: string,
}

class UserService extends BaseService {
  connectWithFacebook(fb_user_id: string, fb_access_token: string) {
    return this.client.post('/account/facebook/connect', { fb_user_id, fb_access_token })
  }

  connectWithLINE(email: string) {
    return this.client.post('/account/LINE/connect', { email })
  }

  getProviderAccounts() {
    return this.client.get('/account/connected-accounts')
  }

  getFacebookUser(fbUserId: string) {
    return this.client.get(`/account/facebook/${fbUserId}`)
  }

  getYahooAccount(authorizationBusinessId: string) {
    return this.client.get(`/account/yahoo/${authorizationBusinessId}`)
  }

  getLINEAccount(accountId: string) {
    return this.client.get(`/account/LINE/${accountId}`)
  }

  getGoogleAccount(accountId: string) {
    return this.client.get(`/account/google/${accountId}`)
  }

  async getTeadsAccount(): Promise<ITeadsAccount | null> {
    const response = await this.client.get(`/account/teads/`)
    const payload = response.data as ITeadsAccount[]
    if (payload.length === 0) {
      return null
    }
    return payload[0]
  }

  async postTeadsAccount(payload: ITeadsAccount): Promise<ITeadsAccount> {
    const response = await this.client.post(`/account/teads/`, payload)
    return response.data as ITeadsAccount
  }

  getUserStats() {
    return this.client.get(`/account/user-stats`)
  }

  deleteAccount(provider: string, accountId: string) {
    provider = provider.toLowerCase()
    if (provider === 'line') {
      provider = 'LINE'
    } else if (provider === 'teads') {
      return this.client.delete(`/account/${provider}/`)
    }
    return this.client.delete(`/account/${provider}/${accountId}`)
  }
}

export default UserService
