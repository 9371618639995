import moment from 'moment'
import { useState, useEffect } from 'react'
import { useAppDispatch } from '../../app/hooks'
import { setCount } from './notificationsSlice'
import DashboardLayout from '../../layouts/DashboardLayout'
import Breadcrumb from '../../components/Breadcrumb'
import NotificationService from '../../services/NotificationService'

import { 
  CheckCircleIcon, 
  InformationCircleIcon,
  ExclamationTriangleIcon,
  ShieldExclamationIcon
} from '@heroicons/react/24/solid'

function Notifications() {
  const [isLoading, setIsLoading] = useState(false)
  const [rows, setRows] = useState<NotificationItem[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    async function fetchData() {
      const notificationService = new NotificationService()
      setIsLoading(true)
      try {
        const { data } = await notificationService.getAllNotifications()
        setRows(data)
        const ids = data.map((x: NotificationItem) => x.id)
        await notificationService.markAsRead(ids)
        dispatch(setCount(0))
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }

    document.title = 'Notifications - Syncotron-9000'
    fetchData()
  }, [])

  const pages = [{
    title: 'Dashboard',
    href: '/'
  }, {
    title: 'Notifications'
  }]

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="mt-6 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1 mt-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Notifications
          </h2>
        </div>
      </div>
      <Table isLoading={isLoading} rows={rows} />
    </DashboardLayout>
  )
}

interface TableProps {
  isLoading: boolean
  rows: NotificationItem[]
}

function Table({ isLoading, rows }: TableProps) {
  return (
    <table className="min-w-full mt-5 ring-1 ring-black ring-opacity-10 divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th colSpan={2} className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Notification
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Type
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Date
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {rows.length === 0 && (
          <tr>
            <td className="text-sm py-3 sm:pl-6">
              {isLoading ? 'Loading...' : 'No messages.'}
            </td>
          </tr>
        )}
        {rows.map((row) => (
          <TableRow key={row.id} row={row} />
        ))}
      </tbody>
    </table>
  )
}

interface TableRowProps {
  row: NotificationItem
}

function TableRow({ row }: TableRowProps) {
  return (
    <tr>
      <td className="w-14 py-2">
        {row.level === 'success' && <CheckCircleIcon className="h-6 w-6 mx-auto text-green-500" />}
        {row.level === 'info' && <InformationCircleIcon className="h-6 w-6 mx-auto text-cyan-500" />}
        {row.level === 'warning' && <ShieldExclamationIcon className="h-6 w-6 mx-auto text-yellow-500" />}
        {row.level === 'danger' && <ExclamationTriangleIcon className="h-6 w-6 mx-auto text-red-500" />}
      </td>
      <td className="whitespace-wrap text-sm">
        <p className={row.isNew ? 'font-bold': ''}>
          {row.message}
        </p>
      </td>
      <td className="whitespace-nowrap text-sm text-center">
        {row.category}
      </td>
      <td className="whitespace-nowrap text-sm text-center pr-4">
        <p>{moment(row.createdAt).format('MMM DD, YYYY')}</p>
      </td>
    </tr>
  )
}

export default Notifications 
