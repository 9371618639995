import { AxiosError } from 'axios'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DashboardLayout from '../../layouts/DashboardLayout'
import DataSourceService from '../../services/DataSourceService'
import UserService from '../../services/UserService'
import Breadcrumb from '../../components/Breadcrumb'
import Toggle from '../../components/Toggle'
import Label from '../../components/Label'
import Error403 from '../../components/Error403'
import PrimaryButton from '../../components/PrimaryButton'
import LINEAdAccountDialog from './LINEAdAccountDialog'
import ConfirmationDialog from '../../components/ConfirmationDialog'

function LineAdAccounts() {
  const [isLoading, setIsLoading] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)
  const [account, setAccount] = useState<LINEAccount>()
  const [tableRows, setTableRows] = useState<LINEAdAccount[]>([])
  const [openDialog, setOpenDialog] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [accountToDelete, setAccountToDelete] = useState<LINEAdAccount | null>()
  const { accountId } = useParams()

  useEffect(() => {
    document.title = 'LINE Ad Accounts - Syncotron-9000'
  }, [])

  useEffect(() => {
    async function getLINEAdAccounts(accountId: string) {
      const userService = new UserService()
      const dataService = new DataSourceService()

      try {
        setIsLoading(true)
        let response
        response = await userService.getLINEAccount(accountId)
        setAccount(response.data)
        response = await dataService.getLINEAdAccount(accountId)
        setTableRows(response.data)
      } catch(e) {
        console.error(e)
        if (e instanceof AxiosError && e.response?.status === 403) {
          setUnauthorized(true)
        }
      } finally {
        setIsLoading(false)
      }
    }

    if (accountId) {
      getLINEAdAccounts(accountId)
    }
  }, [accountId])

  function handleOpenDialog(e: React.MouseEvent) {
    e.preventDefault()
    openDialog === false && setOpenDialog(true)
  }

  async function handleAddAccount(adAccountId: string) {
    if (account === undefined) {
      return
    }
    setOpenDialog(false)
    try {
      setIsSubmitting(true)
      const dataService = new DataSourceService()
      const { data } = await dataService.addLINEAdAccount(account.id, adAccountId)
      setTableRows(data)
    } catch(e) {
      console.error(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  async function handleToggleSync(account: LINEAdAccount, enabled: boolean) {
    const dataService = new DataSourceService()
    await dataService.setLINEAdAccountSyncStatus(account.accountId, enabled)
  }

  async function handleDeleteAccount() {

  }

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Data Source', href: '/datasources/connected-accounts' },
    { title: 'LINE' },
  ]

  if (unauthorized) {
    return <Error403 />
  } 

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="md:flex mt-6 md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            LINE Ad Accounts
          </h2>
        </div>
      </div>
      <div className="text-sm mt-5">
        <div className="flex items-center">
          <div className="h-14 w-14 mr-2 flex-shrink-0">
            <img className="h-14 w-14" src={process.env.PUBLIC_URL + "/img/LINE-logo.png"} alt="" />
          </div>
          <div className="ml-0">
            <div className="font-medium text-gray-900">
              {account?.email}
            </div>
            <div className="text-gray-500">
              {account?.status === 'IN_REVIEW' ? (
                <Label text={account.status} className="mt-2" />
              ) : (
                <p>{account?.name}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <p className="mt-5 italic text-sm text-gray-700">Loading...</p>
      ) : (
        <>
          <div className="flex">
            <p className="mt-5 grow text-sm text-gray-900">
              Select the ad accounts that you want to synchronize the data with Syncotron-9000
            </p>
            <div>
              <PrimaryButton 
                isLoading={isSubmitting} 
                onClick={handleOpenDialog}
              >
                {isSubmitting ? 'Adding Account...' : 'New Ad Account'}
              </PrimaryButton>
            </div>
          </div>
          <Table>
            {tableRows.map(account => (
              <TableRow 
                key={account.accountId}
                account={account} 
                onToggleSync={handleToggleSync}
                onDelete={setAccountToDelete}
              />
            ))}
          </Table>
        </>
      )}

      <LINEAdAccountDialog 
        open={openDialog} 
        onSubmit={handleAddAccount}
        onCancel={() => setOpenDialog(false)}
      />

      <ConfirmationDialog
        open={Boolean(accountToDelete)}
        title="Delete Ad Account"
        onCancel={() => setAccountToDelete(null)}
        onConfirm={() => handleDeleteAccount()}
      >
        LINE Ad account <strong>{accountToDelete?.accountId}</strong> will be deleted! 
        Are you sure?
      </ConfirmationDialog>

    </DashboardLayout>
  )
}

interface TableProps {
  children: React.ReactNode
}

function Table({ children }: TableProps) {
  return (
    <table className="min-w-full mt-5 ring-1 ring-black ring-opacity-10 divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Ad Account ID
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Status
          </th>
          <th className="relative py-2 pr-4 sm:pr-6 text-right text-sm">
            Sync Data
          </th>
          <th className="w-10">
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {children}
      </tbody>
    </table>
  )
}

interface TableRowProps {
  account: LINEAdAccount
  onToggleSync: (account: LINEAdAccount, enabled: boolean) => void
  onDelete: (account: LINEAdAccount) => void
}

function TableRow({ account, onToggleSync, onDelete }: TableRowProps) {
  const [syncStatus, setSyncStatus] = useState(account.syncData)

  function handleToggleChange(enabled: boolean) {
    if (account.status === 'ACTIVE') {
      setSyncStatus(enabled)
      try {
        onToggleSync(account, enabled)
      } catch (e) {
        setTimeout(() => {setSyncStatus(false)}, 500)
      }
    }
  }

  let status = null
  switch (account.status) {
    case 'ACTIVE':
      status = <Label success={account.status} />
      break
    case 'INACTIVE':
      status = <Label danger={account.status} />
      break
    case 'IN_REVIEW':
      status = <Label text={account.status} />
      break
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-2 pl-4 text-sm sm:pl-6">
        <div>
          <div className="font-medium text-gray-900">{account.accountId}</div>
          <div className="text-gray-500">{account.name}</div>
        </div>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {status}
      </td>
      <td className="pr-4 sm:pr-6 whitespace-nowrap text-sm text-gray-900 text-right">
        <Toggle enabled={syncStatus} onChange={handleToggleChange} />
      </td>
      <td className="whitespace-nowrap pr-3 pt-1">
        <button onClick={() => onDelete(account)}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </td>
    </tr>
  )
}

export default LineAdAccounts 
