import * as yup from 'yup'
import BaseService from './BaseService'

export interface TeadsReport {
  id?: number
  reportId: string
  descriptiveName?: string
  startDate?: string
  latestDataAt?: string | null
  dimensions: string[]
  metrics: string[]
  syncData: boolean
  createdAt?: string
  fieldsErrors?: string[]
}

export interface SuccessResponse {
  message: string
}

class DataSourceService extends BaseService {
  refreshFacebookAdAccounts(fbUserId: string) {
    return this.client.post(`/datasources/facebook/${fbUserId}/adaccounts`)
  }

  getFacebookAdAccounts() {
    return this.client.get(`/datasources/facebook/adaccounts`)
  }

  setFacebookAdAccountSyncStatus(accountId: string, enabled: boolean) {
    return this.client.post(`/datasources/facebook/adaccounts/${accountId}/sync-status`, { enabled })
  }

  refreshYahooAdAccounts(authorizationBusinessId: string) {
    return this.client.post(`/datasources/yahoo/${authorizationBusinessId}/adaccounts`)
  }

  getYahooAdAccounts(yAccountId: number) {
    return this.client.get(`/datasources/yahoo/${yAccountId}/adaccounts`)
  }

  setYahooAdAccountSyncStatus(accountId: number, yahooAccount: number, enabled: boolean) {
    return this.client.post(`/datasources/yahoo/adaccounts/${accountId}/sync-status`, { yahooAccount, enabled })
  }

  addLINEAdAccount(accountId: string, adAccountId: string) {
    return this.client.post(`/datasources/LINE/${accountId}/adaccounts`, { adAccountId })
  }

  getLINEAdAccount(accountId: string) {
    return this.client.get(`/datasources/LINE/${accountId}/adaccounts`)
  }

  setLINEAdAccountSyncStatus(accountId: string, enabled: boolean) {
    return this.client.post(`/datasources/LINE/adaccounts/${accountId}/sync-status`, { enabled })
  }

  async getGoogleAdAccounts() {
    const response = await this.client.get(`/datasources/google/adaccounts`)
    return await googleAdAccountListSchema.validate(response.data)
  }

  async patchGoogleAdAccountsSyncData(
    adAccount: yup.Asserts<typeof googleAdAccountSchema>, syncData: boolean
  ) {
    const response = await this.client.patch(adAccount.url!, { syncData })
    return await googleAdAccountSchema.validate(response.data)
  }

  async getTeadsReportList(): Promise<TeadsReport[]> {
    const response = await this.client.get(`/datasources/teads/`)
    return response.data as TeadsReport[]
  }

  async getTeadsReport(id: number): Promise<TeadsReport> {
    const response = await this.client.get(`/datasources/teads/${id}/`)
    return response.data as TeadsReport
  }

  async postTeadsReport(data: TeadsReport): Promise<TeadsReport> {
    const response = await this.client.post(`/datasources/teads/`, data)
    return response.data as TeadsReport
  }

  async patchTeadsReport(id: number, data: Partial<TeadsReport>): Promise<TeadsReport> {
    const response = await this.client.patch(`/datasources/teads/${id}/`, data)
    return response.data as TeadsReport
  }

  async syncDataTeadsReport(id: number, syncData: boolean): Promise<TeadsReport> {
    const response = await this.client.patch(`/datasources/teads/${id}/`, { syncData })
    return response.data as TeadsReport
  }

  async fetchTeadsReport(): Promise<TeadsReport[]> {
    const response = await this.client.get(`/datasources/teads/fetch-reports/`)
    return response.data as TeadsReport[]
  }

  async deleteTeadsReport(id: number): Promise<TeadsReport> {
    const response = await this.client.delete(`/datasources/teads/${id}/`)
    return response.data as TeadsReport
  }

  async triggerTeadsDownloadTask(reportId: number | string): Promise<string> {
    const response = await this.client.post<SuccessResponse>(`/tasks/teads/trigger`, { reportId })
    return response.data.message
  }

  async updloadCsv(
    data: any
  ) {
    return await this.client.post(`/datasources/upload/csv`, data)
  }
}

export const googleAdAccountSchema = yup.object({
  customerId: yup.string().required(),
  descriptiveName: yup.string().required(),
  syncData: yup.bool().required(),
  url: yup.string(),
})

export const googleAdAccountListSchema = yup.array().of(googleAdAccountSchema).ensure()

export default DataSourceService
