import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/24/solid'

interface Props {
  pages: {
    title: string
    href?: string
  }[]
}

function Breadcrumb({ pages }: Props) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-2">
        {pages.map((page, index) => (
          <li key={index}>
            <div className="flex items-center">
              {index > 0 && (
                <ChevronRightIcon className="h-5 w-5 mr-2 flex-shrink-0 text-gray-400" aria-hidden="true" />
              )}
              {page.href ? (
                <Link to={page.href} className="text-sm text-indigo-700 hover:text-indigo-500">
                  {page.title}
                </Link>
              ) : (
                <span className="text-sm text-gray-700">
                  {page.title}
                </span>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumb
