import React from 'react'
import { useNavigate } from 'react-router-dom'

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  linkTo?: string
  children: React.ReactNode
}

export default function PrimaryButton(props: Props) {
  const { onClick, isLoading, linkTo, children } = props
  const navigate = useNavigate()

  function handleButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (onClick || linkTo) {
      e.preventDefault()
      onClick && onClick(e)
      linkTo && navigate(linkTo)
    }
  }

  return (
    <button
      type="submit"
      className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
      onClick={handleButtonClick}
    >
      {isLoading && (
        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      )}
      {children}
    </button>
  )
}
