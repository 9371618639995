import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
// import Toggle from '../../components/Toggle'
import Breadcrumb from '../../components/Breadcrumb'
import Error403 from '../../components/Error403'
import { TeadsReportForm } from './TeadsReportForm'

function TeadsReportNew() {
  const [unauthorized, setUnauthorized] = useState(false)

  useEffect(() => {
    document.title = 'New Teads Report - Syncotron-9000'
  }, [])

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Data Source', href: '/datasources/connected-accounts' },
    { title: 'Teads', href: '/datasources/teads' },
    { title: 'New Teads Report' },
  ]

  if (unauthorized) {
    return <Error403 />
  }

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="md:flex mt-6 md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            New Teads Report
          </h2>
        </div>
      </div>
      <div className="text-sm mt-5">
        <div className="flex items-center">
          <div className="h-12 w-12 mr-3 flex-shrink-0">
            <img className="h-12 w-12" src={process.env.PUBLIC_URL + "/img/logo-teads-160x60.svg"} alt="" />
          </div>
        </div>
      </div>
      <p className="mt-5 text-sm text-gray-900">
        Input the Teads Report that you want to synchronize the data with Syncotron-9000.
      </p>
      <TeadsReportForm setUnauthorized={setUnauthorized}/>
    </DashboardLayout>
  )
}

export function emptyTeadsReport() {
  const today = new Date()
  return {
    reportId: '',
    descriptiveName: '',
    startDate: today.toISOString().slice(0, 10), // YYYY-MM-DD
    metrics: [],
    dimensions: [],
    syncData: false,
  }
}

export default TeadsReportNew
