import { useEffect, useState } from 'react'
import DashboardLayout from '../../layouts/DashboardLayout'
import UserService from '../../services/UserService'

interface UserStats {
  connectedSources: {
    total: number
    sources: string[]
  }
  connectedAccounts: {
    total: number
  }
  adAccounts: {
    total: number
    synchronized: number
  }
}

export default function Dashboard() {

  const [userStats, setUserStats] = useState<UserStats>()

  useEffect(() => {
    async function getUserStats() {
      try {
        const userService = new UserService()
        const { data } = await userService.getUserStats()
        setUserStats(data)
      } catch (error) {
        console.log(error)
      }
    }

    document.title = 'Dashboard - Syncotron-9000'
    getUserStats()
  }, [])

  return (
    <DashboardLayout>
      <div className="mt-3 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Dashboard
          </h2>
        </div>
      </div>
      <div className="flex space-x-8 mt-5">
        <div className="grow">
          <div className="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <ConnectedSources userStats={userStats} />
            <ConnectedAccounts userStats={userStats} />
            <AdAccounts userStats={userStats} />
            <Support />
          </div>
        </div>
        <div className="w-72">
          <Announcements />
        </div>
      </div>
    </DashboardLayout>
  )
}

interface CardProps {
  userStats: UserStats | undefined
}

function ConnectedSources({ userStats }: CardProps) {
  let total = 0
  let sources: string[] = []

  const logoImages = {
    'FACEBOOK': 'facebook-logo.svg',
    'YAHOO': 'yahoo-logo.png',
    'LINE': 'LINE-logo.png'
  }

  function getLogo(source: string) {
    source = source.toUpperCase()
    if (source === 'FACEBOOK') {
      return 'facebook-logo.svg'
    } else if (source === 'YAHOO') {
      return 'yahoo-logo.png'
    } else if (source === 'LINE') {
      return 'LINE-logo.png'
    } else if (source === 'GOOGLE') {
      return 'google-logo.png'
    } else if (source === 'TIKTOK') {
      return 'tiktok-logo.png'
    } else if (source === 'TEADS') {
      return 'logo-teads-160x60.svg'
    }
  }

  if (userStats) {
    total = userStats.connectedSources.total
    sources = userStats.connectedSources.sources
  }

  return (
    <div className="overflow-hidden rounded-lg shadow bg-white px-4 py-5 sm:p-6">
      <dd className="truncate text-xl font-semibold tracking-tight text-gray-800">
        {total} Connected Source{total===1 ? '' : 's'}
      </dd>
      <div className="mt-1 truncate text-sm font-medium text-gray-500 flex">
        {sources.map(source => (
          <img key={source} className="h-12 w-12" src={process.env.PUBLIC_URL + '/img/' + getLogo(source)} alt="" />
        ))}
      </div>
    </div>
  )
}

function ConnectedAccounts({ userStats }: CardProps) {
  let total = 0
  if (userStats) {
    total = userStats.connectedAccounts.total
  }

  return (
    <div className="overflow-hidden rounded-lg shadow bg-white px-4 py-5 sm:p-6">
      <dd className="truncate text-xl font-semibold tracking-tight text-gray-800">
        {total} Account{total===1 ? '' : 's'}
      </dd>
      <div className="mt-6 truncate text-sm font-medium text-gray-500 flex">
        Managed account{total===1 ? '' : 's'}
      </div>
    </div>
  )
}

function AdAccounts({ userStats }: CardProps) {
  let total = 0
  let synchronized = 0

  if (userStats) {
    total = userStats.adAccounts.total
    synchronized = userStats.adAccounts.synchronized
  }

  return (
    <div className="overflow-hidden rounded-lg shadow bg-white px-4 py-5 sm:p-6">
      <dd className="truncate text-xl font-semibold tracking-tight text-gray-800">
        {total} Ad Account{total===1 ? '' : 's'}
      </dd>
      <div className="mt-6 truncate text-sm font-medium text-gray-500 flex">
        {synchronized} synchronized daily
      </div>
    </div>
  )
}

function Support() {
  return (
    <div className="overflow-hidden rounded-lg shadow bg-white px-4 py-5 sm:p-6">
      <dd className="flex truncate tracking-tight">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mt-1 mr-2 w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
        </svg>
        <span className="text-xl font-semibold text-gray-800">Support</span>
      </dd>
      <div className="mt-6 truncate text-sm font-medium text-gray-500 flex">
        <a href="/help" className="text-blue-700" target="_blank">How can we help?</a>
      </div>
    </div>
  )
}

function Announcements() {
  return (
    <table className="min-w-full ring-1 ring-black ring-opacity-10 divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th className="p-4 text-left text-sm font-semibold text-gray-900">
            Announcements
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        <tr>
          <td className="p-4">
            <h3 className="text-base font-semibold">
              LINE connector is live
            </h3>
            <p className="text-sm my-2">
              Analyze the performance of your LINE Ads using our Line Ads Connector
              for Looker Studio.
            </p>
            <p className="text-gray-400 text-xs">
              Dec 17, 2022
            </p>
          </td>
        </tr>
        <tr>
          <td className="p-4">
            <h3 className="text-base font-semibold">
              Yahoo! JAPAN Ads on Looker Studio
            </h3>
            <p className="text-sm my-2">
              We have published the Yahoo! JAPAN Ads connectors for both the Search Ads and
              Display Ads.
            </p>
            <p className="text-gray-400 text-xs">
              Dec 17, 2022
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

function UserStats() {
  interface UserStat {
    name: string
    stat: number
  }

  const [userStats, setUserStats] = useState<UserStat[]>([])

  useEffect(() => {
    async function getUserStats() {
      try {
        const userService = new UserService()
        const { data } = await userService.getUserStats()
        setUserStats(data)
      } catch (error) {
        console.log(error)
      }
    }
    getUserStats()
  }, [])

  return (
    <div className="bg-slate-100 mt-5 p-3 rounded-lg">
      <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3">
        {userStats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-lg shadow bg-white px-4 py-5 sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

