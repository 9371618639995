import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import UserService from '../../services/UserService'
import DashboardLayout from '../../layouts/DashboardLayout'
import PrimaryButton from '../../components/PrimaryButton'
import Breadcrumb from '../../components/Breadcrumb'
import ConfirmationDialog from '../../components/ConfirmationDialog'
import Label from '../../components/Label'
import AlertInfo from '../../components/AlertInfo'
import { XMarkIcon } from '@heroicons/react/20/solid'

type ProviderAccount = FacebookUser | YahooAccount | GoogleAccount |
                       TiktokAccount | LINEAccount | TeadsAccount

interface AccountToDelete {
  provider: string
  id: string
  title: string
}

function ProviderAccounts() {
  const [providerAccounts, setProviderAccounts] = useState<ProviderAccount[]>([])
  const [isRetrievingData, setIsRetrievingData] = useState(false)
  const [infoMessage, setInfoMessage] = useState('')
  const [accountToDelete, setAccountToDelete] = useState<AccountToDelete|null>(null)
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const userService = new UserService()

  useEffect(() => {
    async function getProviderAccounts() {
      setIsRetrievingData(true)
      try {
        const { data } = await userService.getProviderAccounts()
        setProviderAccounts(data)
      } catch (error) {
        console.log(error)
      } finally {
        setIsRetrievingData(false)
      }
    }

    document.title = 'Connected Accounts - Syncotron-9000'
    getProviderAccounts()
  }, [])

  function confirmDeletion(provider: string, id: string, title: string) {
    setAccountToDelete({provider, id, title})
    setOpenConfirmation(true)
  }

  async function handleDelete() {
    if (accountToDelete === null) {
      return
    }

    const { provider, id, title } = accountToDelete

    try {
      setOpenConfirmation(false)
      setInfoMessage(`Removing ${provider} account ${title}...`)

      const userService = new UserService()
      await userService.deleteAccount(provider, id)
      const { data } = await userService.getProviderAccounts()
      setProviderAccounts(data)
    } catch (e) {
      console.error(e)
    } finally {
      setAccountToDelete(null)
      setInfoMessage('')
    }
  }

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Data Source' },
  ]

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="mt-6 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Connected Accounts
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            Select the account below to view the available ad accounts
          </p>
        </div>
        <div className="flex md:mt-0 md:ml-4">
          <PrimaryButton linkTo="/datasources/connected-accounts/new">
            New Data Source
          </PrimaryButton>
        </div>
        <div className="flex md:mt-0 md:ml-4">
          <PrimaryButton linkTo="/datasources/upload/csv">
            Upload CSV
          </PrimaryButton>
        </div>
      </div>

      {infoMessage && (
        <AlertInfo className="mt-5" spinner={true}>
          {infoMessage}
        </AlertInfo>
      )}

      <Table>
        {providerAccounts.length === 0 && (
          <tr>
            <td className="text-sm py-3 pl-5">
              {isRetrievingData ? 'Loading...' : 'No connected accounts.'}
            </td>
          </tr>
        )}
        {providerAccounts.map((row, index) => {
          let key = `${index}`
          return <TableRow key={key} row={row} onDelete={confirmDeletion} />
        })}
      </Table>

      <ConfirmationDialog
        open={openConfirmation}
        onCancel={() => setOpenConfirmation(false)}
        onConfirm={handleDelete}
      >
        Are you sure you want to delete {(accountToDelete?.provider || '') + ' '} Account <br />
        <span className="text-gray-900 font-bold">{(accountToDelete?.title || '')}</span>? <br /><br />
        All data will be lost and this action cannot be undone.
      </ConfirmationDialog>
    </DashboardLayout>
  )
}

interface TableProps {
  children: React.ReactNode
}

function Table({ children }: TableProps) {
  return (
    <table className="min-w-full mt-5 ring-1 ring-black ring-opacity-10 divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Account
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Status
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Last Used
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {children}
      </tbody>
    </table>
  )
}

interface TableRowProps {
  row: ProviderAccount
  onDelete: (provider: string, id: string, title: string) => void
}

function TableRow({ row, onDelete }: TableRowProps) {
  const [displayPicture, setDisplayPicture] = useState(true)

  let id = ''
  let title = ''
  let subtitle = ''
  let pictureUrl = ''
  let providerLogo = ''
  let status = <Label success={row.status} />

  switch (row.provider) {
    case 'FACEBOOK':
      id = row.fbUserId
      title = row.fbName
      subtitle = row.fbEmail
      pictureUrl = row.fbPictureUrl
      providerLogo = 'facebook-logo.svg'
      if (row.status === 'NEED_REAUTHORIZE') {
        status = <Label danger={row.status} />
      }
      break

    case 'YAHOO':
      id = row.authorizationBusinessId
      title = row.authorizationBusinessId
      pictureUrl = ''
      providerLogo = 'yahoo-logo.png'
      break

    case 'GOOGLE':
      id = row.id
      title = row.name
      subtitle = row.email
      pictureUrl = row.pictureUrl
      providerLogo = 'google-logo.png'
      break

    case 'TIKTOK':
      id = row.tiktokUserId
      title = row.displayName
      subtitle = row.email
      providerLogo = 'tiktok-logo.png'
      break

    case 'LINE':
      id = row.id
      title = row.email
      subtitle = row.name
      providerLogo = 'LINE-logo.png'
      if (row.status === 'IN_REVIEW') {
        status = <Label text={row.status} />
      }
      break

    case 'TEADS':
      id = 'teads'
      title = 'Teads'
      providerLogo = 'logo-teads-160x60.svg'
      break

    default:
      break
  }

  let subtitleLine: React.ReactNode = (
    <div className="text-gray-500">{subtitle}</div>
  )

  let linkTo = `/datasources/${row.provider.toLowerCase()}/${id}/adaccounts`

  if (row.provider === 'TEADS') {
    linkTo = '/datasources/teads'
    subtitleLine = ''
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-2 pl-4 text-sm sm:pl-6">
        <Link
          to={linkTo}
          title="Click to see the available ad accounts"
          className="flex items-center"
        >
          <div className="h-12 w-12 mr-5 flex-shrink-0">
            <img className="h-12 w-12" src={process.env.PUBLIC_URL + "/img/" + providerLogo} alt="" />
          </div>
          {pictureUrl && displayPicture && (
            <div className="h-10 w-10 flex-shrink-0">
              <img
                className="h-10 w-10 rounded-full"
                alt={'Profile picture for ' + row.provider + ' account'}
                src={pictureUrl}
                onError={e => setDisplayPicture(false)}
              />
            </div>
          )}
          <div className="ml-4">
            <div className="font-medium text-gray-900">{title}</div>
            {subtitleLine}
          </div>
        </Link>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-500 text-center">
        {status}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-500 text-center">
        <div className="text-gray-900">
          {row.lastUsed ? moment(row.lastUsed).format('MMM DD, YYYY') : '-'}
        </div>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-500 text-center">
        <button
          onClick={() => onDelete(row.provider, id, title)}
        >
          <XMarkIcon className="mr-1 text-red-500 w-6 h-6" />
        </button>
      </td>
    </tr>
  )
}

export default ProviderAccounts
