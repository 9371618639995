import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'

interface NotificationsState {
  count: number
}

const initialState: NotificationsState = {
  count: 0
}

export const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setCount: (state, action) => {
      state.count = action.payload
    }
  }
})

export const { setCount } = notificationsSlice.actions

export const selectNotifications = (state: RootState) => state.notifications

export default notificationsSlice.reducer
