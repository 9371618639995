import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import DashboardLayout from '../../layouts/DashboardLayout'
import DataSourceService from '../../services/DataSourceService'
import Toggle from '../../components/Toggle'
import Breadcrumb from '../../components/Breadcrumb'
import Error403 from '../../components/Error403'
import UserService from '../../services/UserService'

import AlertError from '../../components/AlertError'

function FacebookAdAccounts() {
  const [isLoading, setIsLoading] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [fbUser, setFbUser] = useState<FacebookUser>()
  const [tableRows, setTableRows] = useState<FacebookAdAccount[]>([])
  const { fbUserId } = useParams()

  useEffect(() => {
    const userService = new UserService()
    const dataService = new DataSourceService()

    async function loadData(fbUserId: string) {
      setErrorMessage('')

      try {
        const { data } = await userService.getFacebookUser(fbUserId)
        setFbUser(data)
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 403) {
          setUnauthorized(true)
        }
        console.error(error)
        return
      }

      try {
        setIsLoading(true)
        const { data } = await dataService.refreshFacebookAdAccounts(fbUserId)
        setTableRows(data)
      } catch (error) {
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.error || 'Unknown error')
        }
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    if (fbUserId !== undefined) {
      loadData(fbUserId)
    }
  }, [fbUserId])

  useEffect(() => {
    document.title = 'Facebook Ad Accounts - Syncotron-9000'
  }, [])

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Data Source', href: '/datasources/connected-accounts' },
    { title: 'Facebook' },
  ]

  if (unauthorized) {
    return <Error403 />
  }

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="md:flex mt-6 md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Facebook Ad Accounts
          </h2>
        </div>
      </div>
      <div className="text-sm mt-3">
        <div className="flex items-center">
          <div className="h-14 w-14 mr-3 flex-shrink-0">
            <img className="h-14 w-14" src={process.env.PUBLIC_URL + "/img/facebook-logo.svg"} alt="" />
          </div>
          <div className="h-10 w-10 flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={fbUser?.fbPictureUrl} alt="" />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">{fbUser?.fbName}</div>
            <div className="text-gray-500">{fbUser?.fbEmail}</div>
          </div>
        </div>
      </div>

      {errorMessage && (
        <AlertError className="mt-5">
          {errorMessage}
        </AlertError>
      )}

      {isLoading ? (
        <p className="mt-5 italic text-sm text-gray-700">Loading...</p>
      ) : (
        <>
          <div className="mt-5 text-sm text-gray-900">
            Select the ad accounts that you want to synchronize the data with Syncotron-9000.
          </div>
          <Table rows={tableRows} />
        </>
      )}
    </DashboardLayout>
  )
}

interface TableProps {
  rows: FacebookAdAccount[]
}

function Table({ rows }: TableProps) {
  return (
    <table className="min-w-full mt-5 ring-1 ring-black ring-opacity-10 divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Ad Account
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Currency
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Timezone
          </th>
          <th className="relative py-2 text-center text-sm">
            Status
          </th>
          <th className="relative py-2 pr-4 sm:pr-6 text-right text-sm">
            Sync Data
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {rows.length === 0 && (
          <tr>
            <td className="text-sm py-3 pl-5 italic">
              No ad accounts
            </td>
          </tr>
        )}
        {rows.map((row) => (
          <TableRow key={row.accountId} row={row} />
        ))}
      </tbody>
    </table>
  )
}

interface TableRowProps {
  row: FacebookAdAccount
}

function TableRow({ row }: TableRowProps) {
  const [syncStatus, setSyncStatus] = useState(row.syncData)

  async function handleToggleChange(enabled: boolean) {
    setSyncStatus(enabled)
    const dataSourceService = new DataSourceService()
    try {
      await dataSourceService.setFacebookAdAccountSyncStatus(row.accountId, enabled)
    } catch (error) {
      setTimeout(() => setSyncStatus(false), 1000)
    }
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-2 pl-4 text-sm sm:pl-6">
          <div>
            <div className="font-medium text-gray-900">{row.name}</div>
            <div className="text-gray-500">{row.accountId}</div>
          </div>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {row.currency}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {row.timezoneName}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {row.accountStatus}
      </td>
      <td className="whitespace-nowrap pr-4 sm:pr-6 text-sm text-gray-900 text-right">
        <Toggle enabled={syncStatus} onChange={handleToggleChange} />
      </td>
    </tr>
  )
}

export default FacebookAdAccounts
