import React, { useState, useEffect } from 'react'
import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'
import { refreshToken } from './features/auth/authSlice'
import { useAppDispatch } from './app/hooks'

import SignIn from "./features/auth/SignIn"
import SignUp from "./features/auth/SignUp"
import ForgotPassword from './features/auth/ForgotPassword'
import Dashboard from './features/dashboard/Dashboard'
import ProviderAccounts from './features/datasources/ProviderAccounts'
import NewProviderAccount from './features/datasources/NewProviderAccount'
import FacebookAdAccounts from './features/datasources/FacebookAdAccounts'
import YahooAdAccounts from './features/datasources/YahooAdAccounts'
import GoogleAdAccounts from './features/datasources/GoogleAdAccounts'
import LineAdAccounts from './features/datasources/LINEAdAccounts'
import TeadsReports from './features/datasources/TeadsReports'
import TeadsReportNew from './features/datasources/TeadsReportNew'
import TeadsReportEdit from './features/datasources/TeadsReportEdit'
import Notifications from './features/notifications/Notifications'
import DataStudioLinks from './features/reports/DataStudioLinks'
import UploadCsvPage from './features/importer/UploadCsv'
import HelpHome from './help/HelpHome'
import HelpPage from './help/HelpPage'
import Error404 from './components/Error404'

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(refreshToken(() => {
      setIsLoading(false)
    }))
  }, [])

  return (
    <div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Routes>
          <Route path="/" element={<Navigate to="/signin" replace={true} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/datasources/connected-accounts" element={<ProviderAccounts />} />
          <Route path="/datasources/connected-accounts/new" element={<NewProviderAccount />} />
          <Route path="/datasources/facebook/:fbUserId/adaccounts" element={<FacebookAdAccounts />} />
          <Route path="/datasources/yahoo/:authorizationBusinessId/adaccounts" element={<YahooAdAccounts />} />
          <Route path="/datasources/google/:accountId/adaccounts" element={<GoogleAdAccounts />} />
          <Route path="/datasources/LINE/:accountId/adaccounts" element={<LineAdAccounts />} />
          <Route path="/datasources/teads" element={<TeadsReports />} />
          <Route path="/datasources/teads/new" element={<TeadsReportNew />} />
          <Route path="/datasources/teads/:id/edit" element={<TeadsReportEdit />} />
          <Route path="/datasources/upload/csv" element={<UploadCsvPage />} />
          <Route path="/reports" element={<DataStudioLinks />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/help" element={<HelpHome />} />
          <Route path="/help/:topic/:page" element={<HelpPage />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      )}
    </div>
  )
}

export default App
