import axios from 'axios'
import React, { useEffect, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { setToken, selectAuth } from './authSlice'
import PrimaryButton from '../../components/PrimaryButton'
import FormFieldErrors from '../../components/FormFieldErrors'

const baseURL = process.env.REACT_APP_API_BASE_URL

interface FormState {
  values: {
    name: string
    email: string
    company: string
    phoneNumber: string
    password: string
    agreement: boolean
  }
  errors: {
    name: string[]
    email: string[]
    company: string[]
    phoneNumber: string[]
    password: string[]
    agreement: string[]
  }
  isLoading: boolean
}

interface FormAction {
  type: string
  value?: string | boolean
}

const initialState: FormState = {
  values: {
    name: '',
    email: '',
    company: '',
    phoneNumber: '',
    password: '',
    agreement: false
  },
  errors: {
    name: [],
    email: [],
    company: [],
    phoneNumber: [],
    password: [],
    agreement: []
  },
  isLoading: false
}

function reducer(state: FormState, action: FormAction) {
  let newstate = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case 'setIsLoading':
      newstate.isLoading = action.value
      break
    case 'setErrors':
      newstate.errors = action.value
      break
    case 'setInitialState': 
      newstate = initialState
      break
    default:
      if (action.type in state.values) {
        newstate.values[action.type] = action.value
      } else {
        throw new Error()
      }
  }

  return newstate
}

export default function SignUp() {
  const [form, dispatch] = useReducer(reducer, initialState)
  const { user } = useAppSelector(selectAuth)
  const appDispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (user.userId) {
      navigate('/dashboard')
    }
  }, [user, navigate])

  useEffect(() => {
    document.title = 'Sign Up - Syncotron-9000'
  }, [])

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    dispatch({type: 'setIsLoading', value: true})
    axios 
      .post(`${baseURL}/account/signup`, form.values)
      .then(response => {
        dispatch({type: 'setInitialState'})
        appDispatch(setToken(response.data))
      })
      .catch(error => {
        console.log(error)
        if (error.response.status === 400) {
          dispatch({type: 'setErrors', value: error.response.data})
        }
      })
      .finally(() => {
        dispatch({type: 'setIsLoading', value: false})
      })
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: e.target.name,
      value: e.target.value
    })
  }

  function borderClass(field: string) {
    if (field in form.errors) {
      return form.errors[field].length ? ' border-red-500 ' : ' border-gray-300 '
    } else {
      return ''
    }
  }

  return (
    <div className="mt-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img src={process.env.PUBLIC_URL + "/img/logo.jpg"} className="w-40 mx-auto" alt="" />
        <h2 className="mt-8 text-center text-3xl font-bold tracking-tight text-gray-900">
          Create new account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Already have an account? Please{' '}
          <Link to="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
            sign in
          </Link>
        </p>
      </div>
      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8 px-4 sm:rounded-lg sm:px-10">
          <form method="POST" noValidate autoComplete="off" className="space-y-5" onSubmit={handleFormSubmit}>
            <div>
              <label>
                <span className="block text-sm font-medium text-gray-700">
                  Name
                </span>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={form.values.name}
                  onChange={handleInputChange}
                  className={"block w-full mt-1 appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" + borderClass('name')}
                />
              </label>
              <FormFieldErrors errors={form.errors.name} />
            </div>
            <div>
              <label>
                <span className="block text-sm font-medium text-gray-700">
                  Email
                </span>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={form.values.email}
                  onChange={handleInputChange}
                  className={"block w-full mt-1 appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" + borderClass('email')}
                />
              </label>
              <FormFieldErrors errors={form.errors.email} />
            </div>
            <div>
              <label>
                <span className="block text-sm font-medium text-gray-700">
                  Company
                </span>
                <input
                  id="company"
                  name="company"
                  type="text"
                  value={form.values.company}
                  onChange={handleInputChange}
                  className={"block w-full mt-1 appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" + borderClass('company')}
                />
              </label>
              <FormFieldErrors errors={form.errors.company} />
            </div>
            <div>
              <label>
                <span className="block text-sm font-medium text-gray-700">
                  Phone Number
                </span>
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  value={form.values.phoneNumber}
                  onChange={handleInputChange}
                  className={"block w-full mt-1 appearance-none rounded-md border border-gray-300 px-3 py-2 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" + borderClass('phoneNumber')}
                />
              </label>
              <FormFieldErrors errors={form.errors.phoneNumber} />
            </div>
            <div>
              <label>
                <span className="block text-sm font-medium text-gray-700">
                  Password
                </span>
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={form.values.password}
                  onChange={handleInputChange}
                  className={"block w-full mt-1 appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" + borderClass('password')}
                />
                <FormFieldErrors errors={form.errors.password} />
              </label>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-start">
                <input
                  id="agreement"
                  name="agreement"
                  type="checkbox"
                  onChange={e => dispatch({type: 'agreement', value: e.target.checked})} 
                  className="h-4 w-4 mt-1 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="agreement" className="ml-2 block text-sm text-gray-900 leading-6">
                  I have read and agree with the {' '}
                  <a href="https://digitalwill.co.jp/terms-of-service/" className="text-indigo-500" target="_blank" rel="noreferrer">
                    Terms of Use
                  </a> 
                  {' '} and {' '}
                  <a href="https://digitalwill.co.jp/privacy-policy/" className="text-indigo-500" target="_blank" rel="noreferrer">
                    Privacy Policy
                  </a>.
                </label>
              </div>
            </div>
            <FormFieldErrors errors={form.errors.agreement} />

            <div>
              <PrimaryButton isLoading={form.isLoading}>
                {form.isLoading ? 'Signing up...' : 'Sign Up'}
              </PrimaryButton>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}
