import BaseService from './BaseService'


class NotificationService extends BaseService {
  getNewNotificationCount() {
    return this.client.get('/notifications/count')
  }

  getAllNotifications() {
    return this.client.get('/notifications')
  }

  getRecentNotifications(limit: number) {
    return this.client.get(`/notifications?limit=${limit}`)
  }

  markAsRead(notificationIds: number[]) {
    const ids = notificationIds.join(',')
    return this.client.post('/notifications/mark-as-read', { ids })
  }

  markAllAsRead() {
    return this.client.post('/notifications/mark-as-read')
  }
}

export default NotificationService
