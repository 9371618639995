interface LabelProps {
  success?: string
  warning?: string
  danger?: string
  text?: string
  className?: string
}

function Label({ success, warning, danger, text, className }: LabelProps) {
  let color = ''
  if (success) {
    color = 'bg-green-100'
    text = success
  } else if (warning) {
    color = 'bg-yellow-100'
    text = warning
  } else if (danger) {
    color = 'bg-red-100'
    text = danger
  } else {
    color = 'bg-gray-100'
  }

  text = text?.replace('_', ' ')

  return (
    <span className={color + ' ' + className + " inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-gray-800"}>
      {text}
    </span>
  )
}

export default Label 
