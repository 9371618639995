import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'

interface Props {
  children: React.ReactNode
}

function HelpLayout({ children }: Props) {
  return (
      <div className="min-h-full flex flex-col h-screen">
        <div className="max-w-full px-4 sm:px-6 lg:px-8 border-b border-gray-200">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/dashboard">
                  <img src={process.env.PUBLIC_URL + '/img/logo.jpg'} className="h-9 w-auto lg:block" alt="" />
                </Link>
                <h2 className="ml-5 font-semibold">
                  Help Center
                </h2>
              </div>
            </div>

            <div className="hidden md:flex items-center sm:w-64 lg:w-96">
              <div className="w-full">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    id="search"
                    name="search"
                    className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Search"
                    type="search"
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>

            <div className="hidden sm:ml-6 sm:flex sm:items-center"> 



              <Link to="/dashboard" className="w-full text-sm text-blue-700 text-right">
                Go to Syncotron-9000 →
              </Link>
            </div>
          </div>
        </div>
        <div className="flex-grow min-w-full py-3 px-4 sm:px-6 lg:px-8">
          {children}
        </div>
        <Footer />
      </div>
  )
}

function Footer() {
  return (
    <footer className="bg-white mt-10">
      <div className="mx-auto max-w-full pb-3 px-8">
        <p className="text-sm text-gray-400 text-right">
          <a href="https://digitalwill.co.jp/privacy-policy/" className="text-blue-500 hover:text-blue-700" target="_blank">
            Privacy Policy
          </a>
          {' '}&bull;{' '}
          <a href="https://digitalwill.co.jp/terms-of-service/" className="text-blue-500 hover:text-blue-700" target="_blank">
            Terms of Service
          </a>
          {' '}
          &copy; 2022 Digital Will Inc. 
        </p>
      </div>
    </footer>
  )
}

export default HelpLayout
