import axios from 'axios'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import HelpLayout from '../layouts/HelpLayout'

const baseURL = process.env.REACT_APP_HELP_BASE_URL

function HelpPage() {
  const { topic, page } = useParams()
  const [html, setHtml] = useState('')

  useEffect(() => {
    axios.get(`${baseURL}/${topic}/${page}`)
      .then(response => {
        setHtml(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [topic, page])

  return (
    <HelpLayout>
      <div className="mx-auto my-5 max-w-3xl">
        <div dangerouslySetInnerHTML={{__html: html}} />
      </div>
    </HelpLayout>
  )
}

export default HelpPage
