import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../app/hooks'
import { selectAuth } from '../auth/authSlice'
import DashboardLayout from '../../layouts/DashboardLayout'
import UserService from '../../services/UserService'
import Breadcrumb from '../../components/Breadcrumb'
import AlertError from '../../components/AlertError'
import AlertInfo from '../../components/AlertInfo'
import ConnectLINEDialog from './ConnectLINEDialog'
import ConnectTeadsDialog from './ConnectTeadsDialog'


function NewProviderAccount() {
  const { user } = useAppSelector(selectAuth)
  const [isConnectingFacebook, setIsConnectingFacebook] = useState(false)
  const [connectLINE, setConnectLINE] = useState(false)
  const [connectTeads, setConnectTeads] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const userService = new UserService()
  const navigate = useNavigate()

  useEffect(() => {
    window.fbAsyncInit = function() {
      FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        version: 'v15.0'
      })
    }

    ;(function(d, s, id){
      if (d.getElementById(id)) {return;}
      var fjs = d.getElementsByTagName(s)[0];
      var js = d.createElement(s) as HTMLScriptElement;
      js.src = "https://connect.facebook.net/en_US/sdk.js"; js.id = id;
      if (fjs.parentNode) {fjs.parentNode.insertBefore(js, fjs);}
    }(document, 'script', 'facebook-jssdk'))

    document.title = 'Connect New Account - Syncotron-9000'
  }, [])

  function handleFacebookConnect(e: React.MouseEvent) {
    e.preventDefault()
    FB.login((response: fb.StatusResponse) => {
      if (response.status === 'connected') {
        setIsConnectingFacebook(true)
        setErrorMessage('')
        const { userID, accessToken } = response.authResponse
        userService
          .connectWithFacebook(userID, accessToken)
          .then(response => {
            navigate('/datasources/connected-accounts')
          })
          .catch(error => {
            console.error(error)
            if (error.response?.data) {
              setErrorMessage(error.response?.data.error)
            }
          })
          .finally(() => {
            setIsConnectingFacebook(false)
          })
     }
    }, { scope: 'public_profile,email,ads_read' })
  }

  async function handleYahooConnect(e: React.MouseEvent) {
    e.preventDefault()
    const baseURL = process.env.REACT_APP_API_BASE_URL
    const session = `${user.userId}:${user.emailSignature}`
    window.location.href = `${baseURL}/account/yahoo2/auth?session=${session}`
  }

  async function handleGoogleConnect(e: React.MouseEvent) {
    e.preventDefault()
    const baseURL = process.env.REACT_APP_API_BASE_URL
    const session = `${user.userId}:${user.emailSignature}`
    window.location.href = `${baseURL}/account/google2/auth?session=${session}`
  }

  function handleTiktokConnect(e: React.MouseEvent) {
    e.preventDefault()
    const session = `${user.userId}:${user.emailSignature}`
    window.location.href = `https://api.syncotron9000.com/account/tiktok/auth?session=${session}`
  }

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Data Sources', href: '/datasources/connected-accounts' },
    { title: 'New Data Source' },
  ]

  const providers = [{
    name: 'Facebook',
    logo: 'facebook-logo.svg',
    clickHandler: handleFacebookConnect
  }, {
    name: 'Yahoo! JAPAN',
    logo: 'yahoo-logo.png',
    clickHandler: handleYahooConnect
  }, {
    name: 'LINE',
    logo: 'LINE-logo.png',
    clickHandler: () => {setConnectLINE(true)}
  }, {
    name: 'Google',
    logo: 'google-logo.png',
    clickHandler: handleGoogleConnect
  }, {
    name: 'TikTok',
    logo: 'tiktok-logo.png',
    clickHandler: handleTiktokConnect
  }, {
    name: 'Teads',
    logo: 'logo-teads-160x60.svg',
    clickHandler: () => {setConnectTeads(true)}
  }]

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="mt-6 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Connect a new account
          </h2>
          <p className="mt-2 text-sm text-gray-700">
            Choose the provider of the new account
          </p>
        </div>
      </div>
      {isConnectingFacebook && (
        <AlertInfo className="mt-5">Connecting your account with Facebook...</AlertInfo>
      )}
      {errorMessage && (
        <AlertError className="mt-5">{errorMessage}</AlertError>
      )}
      <ul className="mt-7 grid grid-cols-2 gap-x-4 gap-y-4 md:grid-cols-4 lg:grid-cols-6">
        {providers.map(provider => (
          <li
            key={provider.name}
            className="border border-gray-300 rounded-lg hover:border-purple-600 text-center cursor-pointer py-3 px-5"
            onClick={provider.clickHandler}
          >
            <img className="h-16 w-16 mx-auto" src={process.env.PUBLIC_URL + "/img/" + provider.logo} alt="" />
            <p className="mt-2 text-sm text-gray-900">
              {provider.name}
            </p>
          </li>
        ))}
      </ul>
      <ConnectLINEDialog
        open={connectLINE}
        onCancel={() => setConnectLINE(false)}
      />
      <ConnectTeadsDialog
        open={connectTeads}
        onCancel={() => setConnectTeads(false)}
      />
    </DashboardLayout>
  )
}

export default NewProviderAccount
