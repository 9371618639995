import moment from 'moment'
import React, { useState, Fragment } from 'react'
import { useAppSelector, useAppDispatch, useInterval } from '../../app/hooks'
import { BellIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { Popover, Transition } from '@headlessui/react'
import { 
  selectNotifications, 
  setCount, 
} from './notificationsSlice'
import { 
  CheckCircleIcon, 
  InformationCircleIcon,
  ShieldExclamationIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'
import NotificationService from '../../services/NotificationService'

function NotificationsPopup() {
  const [isLoading, setIsLoading] = useState(false)
  const [notifications, setNotifications] = useState<NotificationItem[]>([])
  const { count } = useAppSelector(selectNotifications)
  const dispatch = useAppDispatch()
  const notificationService = new NotificationService()

  useInterval(() => {
    async function getNewNotificationCount() {
      try {
        const { data } = await notificationService.getNewNotificationCount()
        dispatch(setCount(data.count))
      } catch (error) {
        console.log(error)
      }
    }

    getNewNotificationCount()
  }, 5000)

  async function getRecentNotifications() {
    setIsLoading(true)
    try {
      const { data } = await notificationService.getRecentNotifications(5)
      await notificationService.markAllAsRead()
      setNotifications(data)
      dispatch(setCount(0))
    } catch(error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button 
            className="relative rounded-full bg-white p-1 focus:outline-none"
            onClick={(e:any) => getRecentNotifications()}
          >
            <BellIcon className="h-6 w-6" aria-hidden="true" />
            <span className="absolute inset-0 object-right-top -mr-6">
              {count > 0 && (
                <div className="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                  {count} 
                </div>
              )}
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 mt-1 z-10 w-72 max-w-xs transform px-2 sm:px-0">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative grid gap-3 pb-3 divide-y bg-white">
                  {notifications.length == 0 && (
                    <p className="pt-3 px-3 text-sm text-gray-700 italic">
                      {isLoading ? 'Loading...' : 'No recent notifications.'}
                    </p>
                  )}
                  {notifications.map(notification => (
                    <div key={notification.id} className="flex items-start px-3 pt-3 transition duration-150 ease-in-out">
                      <div className="shrink-0">
                        {notification.level === 'success' && <CheckCircleIcon className="w-6 text-green-500" />}
                        {notification.level === 'info' && <InformationCircleIcon className="w-6 text-cyan-500" />}
                        {notification.level === 'warning' && <ShieldExclamationIcon className="w-6 text-yellow-500" />}
                        {notification.level === 'danger' && <ExclamationTriangleIcon className="w-6 text-red-500" />}
                      </div>
                      <div className="ml-2">
                        <p className={'text-sm text-gray-700 ' + (notification.isNew ? 'font-semibold': '')}>
                          {notification.message}
                        </p>
                        <p className="text-xs italic text-gray-400">{moment(notification.createdAt).fromNow()}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="bg-gray-50 border-t-2 px-3 pt-1 pb-2 border-gray-100 text-right">
                  <Link to="/notifications" className="text-sm text-blue-800">
                    View all notifications
                  </Link>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

export default NotificationsPopup
