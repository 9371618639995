import { AxiosError } from 'axios'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { XMarkIcon, PencilIcon, PlusIcon, ArrowPathIcon, EyeIcon, EyeSlashIcon, ExclamationTriangleIcon, CloudArrowDownIcon } from '@heroicons/react/20/solid'
import DashboardLayout from '../../layouts/DashboardLayout'
import DataSourceService, { TeadsReport } from '../../services/DataSourceService'
import UserService, { ITeadsAccount } from '../../services/UserService'
import Toggle from '../../components/Toggle'
import Breadcrumb from '../../components/Breadcrumb'
import Error403 from '../../components/Error403'


function TeadsReports() {
  const [isLoading, setIsLoading] = useState(false)
  const [isKeyVisible, setKeyVisible] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)
  const [teadsAccount, setTeadsAccount] = useState<ITeadsAccount | null>(null)
  const [tableRows, setTableRows] = useState<TeadsReport[]>([])

  useEffect(() => {
    document.title = 'Teads - Syncotron-9000'

    // loading teads reports list
    const dataService = new DataSourceService()
    const userService = new UserService()
    setIsLoading(true)
    Promise.all([
      userService.getTeadsAccount(),
      dataService.getTeadsReportList(),
    ]).then(
      ([_teadsAccount, teadsReportList]) => {
        setTeadsAccount(_teadsAccount)
        setTableRows(teadsReportList)
      }
    ).catch(
      (error) => {
        console.error(error)
        if (error instanceof AxiosError && error.response?.status === 403) {
          setUnauthorized(true)
        }
      }
    ).finally(() => {
      setIsLoading(false)
    })
  }, [])

  const handleFetchReport = async () => {
    const dataService = new DataSourceService()
    setIsLoading(true)
    try {
      const teadsReportList = await dataService.fetchTeadsReport()
      setTableRows(teadsReportList)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async (id: number) => {
    const dataService = new DataSourceService()
    setIsLoading(true)
    try {
      await dataService.deleteTeadsReport(id)
      setTableRows((prev) => prev.filter((item) => item.id !== id))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Data Source', href: '/datasources/connected-accounts' },
    { title: 'Teads' },
  ]

  if (unauthorized) {
    return <Error403 />
  }

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="md:flex mt-6 md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Teads
          </h2>
        </div>
      </div>
      <div className="text-sm mt-5">
        <div className="flex flex-col">
          <div className="h-12 w-24 mr-3">
            <img className="h-12 w-24" src={process.env.PUBLIC_URL + "/img/logo-teads-80x30.svg"} alt="" />
          </div>
          <div className="flex items-center text-gray-600 mt-2">
            <div>API Key</div>
            <button
              className="mx-2"
              onClick={() => setKeyVisible((prev) => !prev)}
            >
              {isKeyVisible ? (
                <EyeSlashIcon className="h-4 w-5" />
              ) : (
                <EyeIcon className="h-4 w-5" />
              )}
            </button>
            <div>
              {isKeyVisible ? (teadsAccount?.apiKey ?? "-") : "****************" }
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <p className="mt-5 italic text-sm text-gray-700">Loading...</p>
      ) : (
        <>
          <div className="flex flex-row items-baseline gap-3">
            <div className="mt-5 text-sm text-gray-900">
              Select the teads reports that you want to synchronize the data with Syncotron-9000.
            </div>
            <div className="flex-grow"></div>
            <div>
              <button
                onClick={handleFetchReport}
                type="button"
                disabled={isLoading}
                className="mt-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                title="Fetch and add Report IDs from Teads automatically"
              >
                <ArrowPathIcon className="mr-2 h-4 w-4" />
                Fetch and Add
              </button>
            </div>
            <div>
              <Link
                to="/datasources/teads/new"
                className="mt-5 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                title="Add new Teads Report ID Manually"
              >
                <PlusIcon className="mr-2 h-4 w-4" />
                Add Manually
              </Link>
            </div>
          </div>
          <Table rows={tableRows} handleDelete={handleDelete} />
        </>
      )}
    </DashboardLayout>
  )
}

function Table({ rows, handleDelete }: {
  rows: TeadsReport[]
  handleDelete: (id: number) => Promise<void>
}) {
  return (
    <table className="min-w-full mt-5 ring-1 ring-black ring-opacity-10 divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Report ID
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Descriptive Name
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Start Date
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Latest Data At
          </th>
          <th className="relative py-2 pr-4 sm:pr-6 text-right text-sm">
            Sync Data
          </th>
          <th className="relative py-2 pr-4 sm:pr-6 text-right text-sm">
            &nbsp;
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {rows.length === 0 && (
          <tr>
            <td className="text-sm py-3 pl-5">
              No ad accounts found.
            </td>
          </tr>
        )}
        {rows.map((row) => (
          <TableRow key={row.id} row={row} handleDelete={handleDelete}/>
        ))}
      </tbody>
    </table>
  )
}

function TableRow({ row, handleDelete }: {
  row: TeadsReport
  handleDelete: (id: number) => Promise<void>
}) {
  const [syncStatus, setSyncStatus] = useState(row.syncData)

  async function handleToggleChange(enabled: boolean) {
    setSyncStatus(enabled)
    const dataService = new DataSourceService()
    try {
      await dataService.syncDataTeadsReport(row.id!, enabled)
    } catch (error) {
      setTimeout(() => setSyncStatus(false), 1000)
    }
  }

  const hasFieldsErrors = Array.isArray(row.fieldsErrors) && row.fieldsErrors.length > 0

  return (
    <tr>
      <td className="whitespace-nowrap text-sm text-gray-900 py-2 pl-4 sm:pl-6 flex gap-1">
        <div>
          {row.reportId}
        </div>
        {hasFieldsErrors && (
          <ExclamationTriangleIcon title={row.fieldsErrors!.join(", ")} className="text-red-700 w-5 h-6"/>
        )}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {row.descriptiveName}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {row.startDate ?? '-'}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {row.latestDataAt ?? '-'}
      </td>
      <td className="whitespace-nowrap pr-4 sm:pr-6 text-sm text-gray-900 text-right">
        <Toggle
          enabled={syncStatus}
          onChange={handleToggleChange}
          disabled={hasFieldsErrors}
          title={hasFieldsErrors ? row.fieldsErrors!.join(", ") : ""}
        />
      </td>
      <td className="whitespace-nowrap text-sm text-gray-500 text-center">
        <div className="flex flex-row justify-end">

          {syncStatus && (
            <button
              className="mr-2 hover:rounded-full hover:bg-gray-300 p-1 transition-background-color duration-300"
              title="Trigger Sync"
              onClick={async () => {
                const confirmed = window.confirm(
                  "This process could take 15 minutes or more,\n" +
                  "and could cause downtime in reports.\n" +
                  "Are you sure you want to start the sync process?"
                )
                if (confirmed) {
                  // start sync process
                  const dataService = new DataSourceService()
                  const message = await dataService.triggerTeadsDownloadTask(row.reportId)
                  alert(message)
                }
              }}
            >
              <CloudArrowDownIcon className="text-red-700 w-5 h-5" />
            </button>
          )}

          <Link
            to={`/datasources/teads/${row.id!}/edit`}
            className="mr-2 hover:rounded-full hover:bg-gray-300 p-1 transition-background-color duration-300"
            title="Edit"
          >
            <PencilIcon className="text-blue-600 w-5 h-5" />
          </Link>

          <button
            className="mr-2 hover:rounded-full hover:bg-gray-300 p-1 transition-background-color duration-300"
            title="Delete"
            onClick={() => {handleDelete(row.id!)}}
          >
            <XMarkIcon className="text-red-700 w-5 h-5" />
          </button>
        </div>
      </td>
    </tr>
  )
}

export default TeadsReports
