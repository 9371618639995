import axios from 'axios'
import React, { useState, useEffect, useReducer } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/PrimaryButton'
import FormFieldErrors from '../../components/FormFieldErrors'
import AlertError from '../../components/AlertError'

const baseURL = process.env.REACT_APP_API_BASE_URL

function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [accessToken, setAccessToken] = useState('')
  const [step, setStep] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Reset your password - Syncotron-9000'
  }, [])

  function handleEnterEmail(email: string) {
    setEmail(email)
    setStep(step+1)
  }

  function handleEnterCode(accessToken: string) {
    setAccessToken(accessToken)
    setStep(step+1)
  }

  function handleSetNewPassword() {
    const successMessage = `
      You have successfully set your new password. Please sign in 
      to continue.
    `
    navigate('/signin', { state: { successMessage } })
  }

  return (
    <>
      {step === 1 && <EnterEmail next={handleEnterEmail} />}
      {step === 2 && <EnterCode email={email} next={handleEnterCode} />}
      {step === 3 && <SetNewPassword accessToken={accessToken} next={handleSetNewPassword} />}
    </>
  )
}

interface EnterEmailProps {
  next: (email: string) => void
}

export function EnterEmail({ next }: EnterEmailProps) {
  const [email, setEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  function handleFindEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!email) {
      setErrorMessage('Enter your email address')
      return
    }
    setIsLoading(true)
    axios 
      .post(`${baseURL}/account/forgot-password/enter-email`, { email })
      .then(response => {
        setErrorMessage('')
        next(email)
      })
      .catch(error => {
        if (error.response.status === 404) {
          setErrorMessage('Your search did not return any results.')
        } else {
          setErrorMessage('Something went wrong. Please retry again later.')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="mt-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img src={process.env.PUBLIC_URL + "/img/logo.jpg"} className="w-40 mx-auto" alt="" />
        <h2 className="mt-8 text-center text-3xl font-bold tracking-tight text-gray-900">Find your account</h2>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8 px-4 sm:rounded-lg sm:px-10">
          <form method="POST" autoComplete="off" className="space-y-5" onSubmit={handleFindEmail}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Please enter your email address below to search for your account.
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="text"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              {errorMessage && (
                <div className="mt-1 text-sm text-red-500">
                  <p>{errorMessage}</p>
                </div>
              )}
            </div>
            <div className="space-y-2">
              <PrimaryButton isLoading={isLoading}>
                {isLoading ? 'Searching...' : 'Search my account'}
              </PrimaryButton>
              <div className="flex w-full justify-center px-4 py-2 text-sm font-medium text-gray-700">
                <Link to="/signin" className="underline text-blue-800">
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

interface EnterCodeProps {
  email: string
  next: (accessToken: string) => void
}

export function EnterCode({ email, next }: EnterCodeProps) {
  const [securityCode, setSecurityCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  function handleConfirmCode(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!email) {
      setErrorMessage('Enter the security code we\'ve sent to your email.')
      return
    }
    setErrorMessage('')
    setIsLoading(true)
    axios 
      .post(`${baseURL}/account/forgot-password/enter-code`, { email, securityCode })
      .then(response => {
        next(response.data.access)
      })
      .catch(error => {
        if ('response' in error && 'data' in error.response) {
          setErrorMessage(error.response.data.message)
        } else {
          setErrorMessage('Something went wrong. Please retry again later.')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div className="mt-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img src={process.env.PUBLIC_URL + "/img/logo.jpg"} className="w-40 mx-auto" alt="" />
        <h2 className="mt-8 text-center text-3xl font-bold tracking-tight text-gray-900">Enter security code</h2>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8 px-4 sm:rounded-lg sm:px-10">
          <form method="POST" autoComplete="off" className="space-y-5" onSubmit={handleConfirmCode}>
            <div>
              <label htmlFor="securityCode" className="block text-sm font-medium text-gray-700">
                Please check your email for a message with your code. Your code is 6 numbers long.
                <input
                  id="securityCode"
                  name="securityCode"
                  type="text"
                  className="block mt-1 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={securityCode}
                  onChange={e => setSecurityCode(e.target.value)}
                />
              </label>
              {errorMessage && (
                <div className="mt-1 text-sm text-red-500">
                  <p>{errorMessage}</p>
                </div>
              )}
            </div>
            <div className="space-y-2">
              <PrimaryButton isLoading={isLoading}>
                {isLoading ? 'Confirming...' : 'Confirm Code'}
              </PrimaryButton>
              <div className="flex w-full justify-center px-4 py-2 text-sm font-medium text-gray-700">
                <Link to="/signin" className="underline text-blue-800">
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

interface SetNewPasswordProps {
  accessToken: string
  next: () => void
}

interface FormState {
  values: {
    password: string
    confirmPassword: string
  }
  errors: {
    password: string[]
    confirmPassword: string[]
  }
  isLoading: boolean
  isExpired: boolean
}

interface FormAction {
  type: string
  value?: string | boolean
}

const initialState: FormState = {
  values: {
    password: '',
    confirmPassword: ''
  },
  errors: {
    password: [],
    confirmPassword: []
  },
  isLoading: false,
  isExpired: false
}

function reducer(state: FormState, action: FormAction) {
  let newstate = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case 'setIsLoading':
      newstate.isLoading = action.value
      break
    case 'setIsExpired':
      newstate = JSON.parse(JSON.stringify(initialState))
      newstate.isExpired = action.value
      break
    case 'setErrors':
      newstate.errors = action.value
      break
    case 'setInitialState': 
      newstate = initialState
      break
    default:
      if (action.type in state.values) {
        newstate.values[action.type] = action.value
      } else {
        throw new Error()
      }
  }

  return newstate
}

export function SetNewPassword({ accessToken, next }: SetNewPasswordProps) {
  const [form, dispatch] = useReducer(reducer, initialState)

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    dispatch({type: 'setIsLoading', value: true})
    const { password, confirmPassword } = form.values
    const headers = { Authorization: `Bearer ${accessToken}` }
    const payload = { password, confirmPassword }
    axios 
      .post(`${baseURL}/account/forgot-password/set-new-password`, payload, { headers })
      .then(response => {
        next()
      })
      .catch(error => {
        const { status, data } = error.response
        if (status === 400) { // Bad request
          dispatch({type: 'setErrors', value: data})
        } else if (status === 401) { // Unauthorized
          dispatch({type: 'setIsExpired', value: true})
        } else {
          console.log(error)
        }
      })
      .finally(() => {
        dispatch({type: 'setIsLoading', value: false})
      })
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    dispatch({
      type: e.target.name,
      value: e.target.value
    })
  }

  function borderClass(field: string) {
    if (field in form.errors) {
      return form.errors[field].length ? ' border-red-500 ' : ' border-gray-300 '
    } else {
      return ''
    }
  }

  return (
    <div className="mt-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img src={process.env.PUBLIC_URL + "/img/logo.jpg"} className="w-40 mx-auto" alt="" />
        <h2 className="mt-8 text-center text-3xl font-bold tracking-tight text-gray-900">Set new password</h2>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8 px-4 sm:rounded-lg sm:px-10">
          <form method="POST" autoComplete="off" className="space-y-5" onSubmit={handleFormSubmit}>
            {form.isExpired ? (
              <AlertError>This session has expired.</AlertError>
            ) : (
              <p className="text-center text-sm text-gray-700">
                Set your new password using the form below. This session is valid for 5 minutes.
              </p>
            )}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                New password
                <input
                  id="password"
                  name="password"
                  type="password"
                  className={"block mt-1 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm " + borderClass('password')}
                  value={form.values.password}
                  onChange={handleInputChange}
                />
              </label>
              <FormFieldErrors errors={form.errors.password} />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                Confirm new password
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  className={"block mt-1 w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm " + borderClass('confirmPassword')}
                  value={form.values.confirmPassword}
                  onChange={handleInputChange}
                />
              </label>
              <FormFieldErrors errors={form.errors.confirmPassword} />
            </div>
            <div className="space-y-2">
              <PrimaryButton isLoading={form.values.isLoading}>
                {form.values.isLoading ? 'Set new password...' : 'Set new password'}
              </PrimaryButton>
              <div className="flex w-full justify-center px-4 py-2 text-sm font-medium text-gray-700">
                <Link to="/signin" className="underline text-blue-800">
                  Cancel
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
