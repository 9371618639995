import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { selectAuth } from '../auth/authSlice'
import { useAppSelector } from '../../app/hooks'
import PrimaryButton from '../../components/PrimaryButton'
import UserService from '../../services/UserService'
import FormFieldErrors from '../../components/FormFieldErrors'
import { AxiosError } from 'axios'

interface Props {
  open: boolean
  onCancel: () => void
}

function ConnectLINEDialog({ open, onCancel }: Props) {
  const { user } = useAppSelector(selectAuth)
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState([])
  const [isConnecting, setIsConnecting] = useState(false)
  const navigate = useNavigate()

  async function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault()
    const userService = new UserService()
    try {
      setIsConnecting(true)
      await userService.connectWithLINE(email)
      navigate('/datasources/connected-accounts')
    } catch (e) {
      console.error(e)
      if (e instanceof AxiosError && e.response?.status == 400) {
        setErrors(e.response.data.email)
      }
    } finally {
      setIsConnecting(false)
    }
  }

  function handleCancel(e: React.MouseEvent) {
    e.preventDefault()
    setEmail('')
    setErrors([])
    onCancel()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <form method="post" action="" onSubmit={handleFormSubmit}>
                  <div>
                    <div className="mx-auto flex h-16 w-16 items-center justify-center">
                      <img src={process.env.PUBLIC_URL + '/img/LINE-logo.png'} />
                    </div>
                    <div className="mt-2 text-center">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Connect with LINE 
                      </Dialog.Title>
                      <div className="mt-4">
                        <p className="text-sm text-gray-500">
                          Please enter the email address of your LINE account below:
                        </p>
                      </div>
                      <div className="mt-2 text-left">
                        <p>
                          <input
                            id="email"
                            name="email"
                            type="text"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            autoFocus={true}
                            placeholder={'e.g: ' + user.email}
                            autoComplete='off'
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                          />
                        </p>
                        <FormFieldErrors errors={errors} />
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <div className="inline-flex w-full justify-center sm:col-start-2">
                      <PrimaryButton isLoading={isConnecting}>
                        {isConnecting ? 'Connecting...' : 'Connect'}
                      </PrimaryButton>
                    </div>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default ConnectLINEDialog
