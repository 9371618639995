import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import DataSourceService, { TeadsReport } from '../../services/DataSourceService';
import SpinLoading from '../../components/SpinLoading';
import { emptyTeadsReport } from './TeadsReportNew';

export function TeadsReportForm({ teadsReport, setUnauthorized }: {
  teadsReport?: TeadsReport;
  setUnauthorized: (value: boolean) => void;
}) {
  const [report, setReport] = useState<TeadsReport>(teadsReport ?? emptyTeadsReport);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (field: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
    setReport((prev) => ({
      ...prev,
      [field]: ev.target.value,
    }));
  };

  const handleSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setIsLoading(true);
    const dataSourceService = new DataSourceService();
    try {
      const startDate = report.startDate ? (new Date(report.startDate)).toISOString().slice(0, 10) : undefined
      if (report.id) {
        await dataSourceService.patchTeadsReport(
          report.id,
          {
            reportId: report.reportId,
            descriptiveName: report.descriptiveName,
            startDate
          }
        )
      } else {
        report.startDate = startDate
        await dataSourceService.postTeadsReport(report);
      }
      navigate('/datasources/teads');
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError && e.response?.status === 401) {
        setUnauthorized(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="py-2">
        <label htmlFor="reportId">Report ID</label>
        <input
          id="reportId"
          type="text"
          value={report.reportId}
          onChange={handleChange("reportId")}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
      </div>
      <div className="py-2">
        <label htmlFor="descriptiveName">Descriptive Name</label>
        <input
          id="descriptiveName"
          type="text"
          value={report.descriptiveName}
          onChange={handleChange("descriptiveName")}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
      </div>
      <div className="py-2">
        <label htmlFor="startDate">Start Date</label>
        <input
          id="startDate"
          type="date"
          value={report.startDate}
          onChange={handleChange("startDate")}
          className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" />
      </div>
      <div className="py-2">
        <button
          type="submit"
          disabled={isLoading}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
        >
          <SpinLoading isLoading={isLoading} /> Save
        </button>
      </div>
    </form>
  );
}
