import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/PrimaryButton'
import Dialog from '../../components/Dialog'
import UserService, { ITeadsAccount } from '../../services/UserService'
import FormFieldErrors from '../../components/FormFieldErrors'
import { AxiosError } from 'axios'

interface Props {
  open: boolean
  onCancel: () => void
}

function emptyAccount(): ITeadsAccount {
  return {
    apiKey: '',
  }
}

function ConnectTeadsDialog({ open, onCancel }: Props) {
  const [account, setAccount] = useState<ITeadsAccount>(emptyAccount)
  const [errors, setErrors] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const userService = new UserService()
    setIsLoading(true)
    userService.getTeadsAccount().then((account) => {
      if (account) setAccount(account)
      else setAccount(emptyAccount())
    }).finally(
      () => setIsLoading(false)
    )
  }, [open])

  async function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault()
    const userService = new UserService()
    try {
      setIsLoading(true)
      await userService.postTeadsAccount(account)
      navigate('/datasources/connected-accounts')
    } catch (e) {
      console.error(e)
      if (e instanceof AxiosError && e.response?.status === 400) {
        setErrors(e.response.data.email)
      }
    } finally {
      setIsLoading(false)
    }
  }

  function handleCancel(e: React.MouseEvent) {
    e.preventDefault()
    setAccount(emptyAccount())
    setErrors([])
    onCancel()
  }

  const handleInputChange = (prop: keyof ITeadsAccount) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccount((prev) => ({
      ...prev,
      [prop]: e.target.value,
    }))
  }

  const title: React.ReactNode = (
    <>
    <div className="mx-auto flex h-16 w-16 items-center justify-center">
      <img src={process.env.PUBLIC_URL + '/img/logo-teads-160x60.svg'} alt="Teads logo"/>
    </div>
    <div className="mt-2 text-center">
      Connect with Teads
    </div>
    </>
  )

  return (
    <Dialog open={open} onCancel={onCancel} title={title}>
      <form method="post" action="" onSubmit={handleFormSubmit}>
        <div>
          <div className="mt-2 text-center">
            <div className="mt-4">
              <p className="text-sm text-gray-500">
                Please enter your Teads account API Key below:
              </p>
            </div>
            <div className="mt-2 text-left">
              <p>
                <input
                  id="apiKey"
                  name="apiKey"
                  type="text"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  autoFocus={true}
                  placeholder={'e.g: 123456-67890-1234567890'}
                  autoComplete='off'
                  value={account.apiKey}
                  onChange={handleInputChange('apiKey')}
                />
              </p>
              <FormFieldErrors errors={errors} />
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <div className="inline-flex w-full justify-center sm:col-start-2">
            <PrimaryButton isLoading={isLoading}>
              {isLoading ? 'Loading...' : 'Save'}
            </PrimaryButton>
          </div>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </form>
    </Dialog>
  )
}

export default ConnectTeadsDialog
