import { AxiosError } from 'axios'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import Breadcrumb from '../../components/Breadcrumb'
import Toggle from '../../components/Toggle'
import DashboardLayout from '../../layouts/DashboardLayout'
import UserService from '../../services/UserService'
import DataSourceService, { googleAdAccountListSchema, googleAdAccountSchema } from '../../services/DataSourceService'
import Error403 from '../../components/Error403'

function GoogleAdAccounts() {
  const [isLoading, setIsLoading] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)
  const [googleAccount, setgoogleAccount] = useState<GoogleAccount>()
  const [adAccountList, setAdAccountList] = useState<yup.Asserts<typeof googleAdAccountListSchema>>([])
  const { accountId } = useParams()

  useEffect(() => {
    document.title = 'Google Ad Accounts - Syncotron-9000'
  }, [])

  useEffect(() => {
    async function getGoogleAdAccount(accountId: string) {
      const userService = new UserService()
      const dataService = new DataSourceService()

      try {
        setIsLoading(true)
        const { data } = await userService.getGoogleAccount(accountId)
        setgoogleAccount(data)
        const response = await dataService.getGoogleAdAccounts()
        setAdAccountList(response)
      } catch(e) {
        console.error(e)
        if (e instanceof AxiosError && e.response?.status === 403) {
          setUnauthorized(true)
        }
      } finally {
        setIsLoading(false)
      }
    }

    if (accountId) {
      getGoogleAdAccount(accountId)
    }
  }, [accountId])

  if (unauthorized) {
    return <Error403 />
  } 

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Data Source', href: '/datasources/connected-accounts' },
    { title: 'Google' },
  ]

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="md:flex mt-6 md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Google Ad Accounts
          </h2>
        </div>
      </div>
      <div className="text-sm mt-5">
        <div className="flex items-center">
          <div className="h-12 w-12 mr-3 flex-shrink-0">
            <img className="h-12 w-12" src={process.env.PUBLIC_URL + "/img/google-logo.png"} alt="" />
          </div>
          <div className="h-10 w-10 flex-shrink-0">
            <img className="h-10 w-10 rounded-full" src={googleAccount?.pictureUrl} alt="" />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">{googleAccount?.name}</div>
            <div className="text-gray-500">{googleAccount?.email}</div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <p className="mt-5 italic text-sm text-gray-700">Loading...</p>
      ) : (
        <>
          <p className="mt-5 text-sm text-gray-900">
            Select the ad accounts that you want to synchronize the data with Syncotron-9000.
          </p>
          <Table rows={adAccountList} onUpdateRow={async (updatedValue) => {
            setAdAccountList(rows => rows.map(row => row.customerId === updatedValue.customerId ? updatedValue : row))
          }}/>
        </>
      )}
    </DashboardLayout>
  )
}

function Table({
  rows,
  onUpdateRow,
}: {
  rows: yup.Asserts<typeof googleAdAccountListSchema>
  onUpdateRow?: (value: yup.Asserts<typeof googleAdAccountSchema>) => Promise<void>
}) {
  return (
    <table className="min-w-full mt-5 ring-1 ring-black ring-opacity-10 divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Ad Account
          </th>
          <th className="relative py-2 pr-4 sm:pr-6 text-right text-sm">
            Sync Data
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {rows.length === 0 && (
          <tr>
            <td className="text-sm py-3 pl-5">
              No ad accounts found.
            </td>
          </tr>
        )}
        {rows.map((row) => (
          <TableRow key={row.customerId} row={row} onUpdateRow={onUpdateRow}/>
        ))}
      </tbody>
    </table>
  )
}

function TableRow({
  row,
  onUpdateRow,
}: {
  row: yup.Asserts<typeof googleAdAccountSchema>
  onUpdateRow?: (value: yup.Asserts<typeof googleAdAccountSchema>) => Promise<void>
}) {
  async function handleToggleChange(enabled: boolean) {
    const dataService = new DataSourceService()
    try {
      const response = await dataService.patchGoogleAdAccountsSyncData(row, enabled)
      if (onUpdateRow) await onUpdateRow(response)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-2 pl-4 text-sm sm:pl-6">
          <div>
            <div className="font-medium text-gray-900">{row.customerId}</div>
            <div className="text-gray-500">{row.descriptiveName}</div>
          </div>
      </td>      
      <td className="whitespace-nowrap pr-4 sm:pr-6 text-sm text-gray-900 text-right">
        <Toggle enabled={row.syncData} onChange={handleToggleChange} />
      </td>
    </tr>
  )
}

export default GoogleAdAccounts
