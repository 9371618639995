import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { setToken, selectAuth } from './authSlice'
import PrimaryButton from '../../components/PrimaryButton'
import AlertSuccess from '../../components/AlertSuccess'
import AlertError from '../../components/AlertError'
import axios, { AxiosError } from 'axios'

const baseURL = process.env.REACT_APP_API_BASE_URL || ''

export default function SignIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const { user } = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { state } = useLocation()

  useEffect(() => {
    if (user.userId) {
      setEmail('')
      setPassword('')
      navigate('/dashboard')
    }
  }, [user, navigate])

  useEffect(() => {
    document.title = 'Sign In - Syncotron-9000'
  }, [])

  async function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    if (!email || !password) {
      setError('Please enter your email and password')
      return
    }

    try {
      setIsLoading(true)
      const response = await axios.post(`${baseURL}/auth/token`, { email, password })
      const { access, refresh } = response.data
      dispatch(setToken({ access, refresh, rememberMe }))
    } catch (error) {
      if (error instanceof AxiosError) {
        let message = 'Something went wrong'
        if (error.code === "ERR_NETWORK") {
          message = 'Cannot established connection with the server'
        } else if (error.response?.data) {
          message = 'Invalid email or password'
        }
        setError(message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="mt-12">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img src={process.env.PUBLIC_URL + "/img/logo.jpg"} className="w-40 mx-auto" alt="" />
        <h2 className="mt-8 text-center text-3xl font-bold tracking-tight text-gray-900">Sign in to your account</h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Don't have an account yet? Please{' '}
          <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
            sign up
          </Link>
        </p>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="py-8 px-4 sm:rounded-lg sm:px-10">
          <form method="POST" autoComplete="off" className="space-y-5" onSubmit={handleFormSubmit}>
            {state?.successMessage && <AlertSuccess>{state?.successMessage}</AlertSuccess>}
            {error && <AlertError>{error}</AlertError>}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="text"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  defaultChecked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link to="/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <PrimaryButton isLoading={isLoading}>
                {isLoading ? 'Signing in...' : 'Sign in'}
              </PrimaryButton>
            </div>

          </form>
        </div>
      </div>
    </div>
  )
}
