import React, { useEffect, Fragment } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { useAppSelector, useAppDispatch } from '../app/hooks'
import { selectAuth, clearToken } from '../features/auth/authSlice'
import NotificationsPopup from '../features/notifications/NotificationsPopup'

interface Props {
  children: React.ReactNode
}

function DashboardLayout({ children }: Props) {
  const { user } = useAppSelector(selectAuth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (!user.userId) {
      navigate('/signin')
    }
  }, [user])

  function handleLogout(e: React.MouseEvent) {
    e.preventDefault()
    dispatch(clearToken())
    navigate('/')
  }

  return (
      <div className="min-h-full flex flex-col h-screen">
        <div className="max-w-full px-4 sm:px-6 lg:px-8 border-b border-gray-200">
          <div className="flex h-16 justify-between">
            <div className="flex">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/dashboard">
                  <img src={process.env.PUBLIC_URL + '/img/logo.jpg'} className="h-9 w-auto lg:block" alt="" />
                </Link>
              </div>
              <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                <Link to="/dashboard" className='border-transparent hover:border-indigo-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'>
                  Dashboard
                </Link>
                <Link to="/datasources/connected-accounts" className='border-transparent hover:border-indigo-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'>
                  Data Sources
                </Link>
                <Link to="/reports" className="border-transparent hover:border-indigo-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  Reports
                </Link>
              </div>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:items-center"> 
              <a href="https://help.syncotron9000.com" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-3">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                </svg>
              </a>

              <NotificationsPopup />

              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm ">
                    <UserCircleIcon className="h-8 w-8 rounded-full" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-4 py-3">
                      <p className="text-sm">Signed in as</p>
                      <p className="truncate text-sm font-medium text-indigo-900">{user.email}</p>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        <button
                          className="block px-4 py-2 text-sm"
                          onClick={handleLogout}
                        >
                          Sign out
                        </button>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <div className="flex-grow min-w-full py-3 px-4 sm:px-6 lg:px-8">
          {children}
        </div>
        <Footer />
      </div>
  )
}

function Footer() {
  return (
    <footer className="bg-white mt-10">
      <div className="mx-auto max-w-full pb-3 px-8">
        <p className="text-sm text-gray-400 text-right">
          <a href="https://digitalwill.co.jp/privacy-policy/" className="text-blue-500 hover:text-blue-700" target="_blank">
            Privacy Policy
          </a>
          {' '}&bull;{' '}
          <a href="https://digitalwill.co.jp/terms-of-service/" className="text-blue-500 hover:text-blue-700" target="_blank">
            Terms of Service
          </a>
          {' '}
          &copy; 2022 Digital Will Inc. 
        </p>
      </div>
    </footer>
  )
}

export default DashboardLayout
