import { AxiosError } from 'axios'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import DashboardLayout from '../../layouts/DashboardLayout'
import DataSourceService from '../../services/DataSourceService'
import Toggle from '../../components/Toggle'
import UserService from '../../services/UserService'
import Breadcrumb from '../../components/Breadcrumb'
import Error403 from '../../components/Error403'

function YahooAdAccounts() {
  const [isLoading, setIsLoading] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)
  const [yahooAccount, setYahooAccount] = useState<YahooAccount>()
  const [tableRows, setTableRows] = useState<YahooAdAccount[]>([])
  const { authorizationBusinessId } = useParams()

  useEffect(() => {
    document.title = 'Yahoo! Ad Accounts - Syncotron-9000'
  }, [])

  useEffect(() => {
    async function getYahooAdAccounts(authorizationBusinessId: string) {
      const userService = new UserService()
      const dataService = new DataSourceService()

      try {
        setIsLoading(true)
        let response 
        response = await userService.getYahooAccount(authorizationBusinessId)
        setYahooAccount(response.data)
        response = await dataService.refreshYahooAdAccounts(authorizationBusinessId)
        setTableRows(response.data)
      } catch(e) {
        console.error(e)
        if (e instanceof AxiosError && e.response?.status === 403) {
          setUnauthorized(true)
        }
      } finally {
        setIsLoading(false)
      }
    }

    if (authorizationBusinessId) {
      getYahooAdAccounts(authorizationBusinessId)
    }
  }, [authorizationBusinessId])

  // Breadcrumb
  const pages = [
    { title: 'Dashboard', href: '/dashboard' },
    { title: 'Data Source', href: '/datasources/connected-accounts' },
    { title: 'Yahoo!' },
  ]

  if (unauthorized) {
    return <Error403 />
  } 

  return (
    <DashboardLayout>
      <Breadcrumb pages={pages} />
      <div className="md:flex mt-6 md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            Yahoo! Ad Accounts
          </h2>
        </div>
      </div>
      <div className="text-sm mt-5">
        <div className="flex items-center">
          <div className="h-12 w-12 mr-3 flex-shrink-0">
            <img className="h-12 w-12" src={process.env.PUBLIC_URL + "/img/yahoo-logo.png"} alt="" />
          </div>
          <div className="ml-4">
            <div className="font-medium text-gray-900">{yahooAccount?.authorizationBusinessId}</div>
            <div className="text-gray-500"></div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <p className="mt-5 italic text-sm text-gray-700">Loading...</p>
      ) : (
        <>
          <p className="mt-5 text-sm text-gray-900">
            Select the ad accounts that you want to synchronize the data with Syncotron-9000.
          </p>
          <Table rows={tableRows} />
        </>
      )}
    </DashboardLayout>
  )
}

interface TableProps {
  rows: YahooAdAccount[]
}

function Table({ rows }: TableProps) {
  return (
    <table className="min-w-full mt-5 ring-1 ring-black ring-opacity-10 divide-y divide-gray-300">
      <thead className="bg-gray-50">
        <tr>
          <th className="py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
            Ad Account
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Platform
          </th>
          <th className="py-2 text-center text-sm font-semibold text-gray-900">
            Delivery Status
          </th>
          <th className="relative py-2 pr-4 sm:pr-6 text-right text-sm">
            Sync Data
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {rows.length === 0 && (
          <tr>
            <td className="text-sm py-3 pl-5">
              No ad accounts found.
            </td>
          </tr>
        )}
        {rows.map((row) => (
          <TableRow key={row.accountId} row={row} />
        ))}
      </tbody>
    </table>
  )
}

interface TableRowProps {
  row: YahooAdAccount
}

function TableRow({ row }: TableRowProps) {
  const [syncStatus, setSyncStatus] = useState(row.syncData)

  async function handleToggleChange(enabled: boolean) {
    setSyncStatus(enabled)
    const dataService = new DataSourceService()
    try {
      await dataService.setYahooAdAccountSyncStatus(row.accountId, row.yahooAccount, enabled)
    } catch (error) {
      setTimeout(() => setSyncStatus(false), 1000)
    }
  }

  return (
    <tr>
      <td className="whitespace-nowrap py-2 pl-4 text-sm sm:pl-6">
          <div>
            <div className="font-medium text-gray-900">{row.accountName}</div>
            <div className="text-gray-500">{row.accountId}</div>
          </div>
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {row.platform}
      </td>
      <td className="whitespace-nowrap text-sm text-gray-900 text-center">
        {row.deliveryStatus}
      </td>
      <td className="whitespace-nowrap pr-4 sm:pr-6 text-sm text-gray-900 text-right">
        <Toggle enabled={syncStatus} onChange={handleToggleChange} />
      </td>
    </tr>
  )
}

export default YahooAdAccounts 
